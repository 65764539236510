import { Time } from '~/lib/time';
import localeDomains from '~/config/locale-domains.js';
import { DEFAULT_LANGUAGE } from '~/config/languages';

const currentDate = new Time();
const tomorrow = Time.formatDate(currentDate.tomorrow, 'YYYY-MM-DD');
const threeDaysFromNow = Time.formatDate(currentDate.addNDays(4), 'YYYY-MM-DD');

export const actions = {};

export const state = () => ({
  currentDate: {
    currentDate: currentDate,
    tomorrow: tomorrow,
    threeDaysFromNow: threeDaysFromNow,
  },
  locales: Object.keys(localeDomains),
  locale: DEFAULT_LANGUAGE.tag,
});
