export const state = /* istanbul ignore next */ () => ({
  trackingData: {
    propertyIds: {},
    top30PropertiesIds: {},
    featurePropertiesIds: {},
    trackingDetails: {},
    propertyTypeKey: {},
    number_results: {},
    page_type: '',
    product_id: '',
    category: '',
    property_name: '',
    property_deposit_percent: '',
    property_class: '',
    property_currency: '',
  },
});

export const mutations = {
  setPropertyIds(state, value) {
    state.trackingData.propertyIds = value;
  },
  setTop30PropertiesIds(state, value) {
    state.trackingData.top30PropertiesIds = value;
  },
  setFeaturePropertiesIds(state, value) {
    state.trackingData.featurePropertiesIds = value;
  },
  setTrackingDetails(state, value) {
    state.trackingData.trackingDetails = value;
  },
  setPropertyTypeKey(state, value) {
    state.trackingData.propertyTypeKey = value;
  },
  setNumberResults(state, value) {
    state.trackingData.number_results = value;
  },
  setPageType(state, value) {
    state.trackingData.page_type = value;
  },
  setProductId(state, value) {
    state.trackingData.product_id = value;
  },
  setPropertyName(state, value) {
    state.trackingData.property_name = value;
  },
  setPropertyDepositPercent(state, value) {
    state.trackingData.property_deposit_percent = value;
  },
  setPropertyClass(state, value) {
    state.trackingData.property_class = value;
  },
  setPropertyCurrency(state, value) {
    state.trackingData.property_currency = value;
  },
};

export const actions = {
  setPropertyIds({ commit }, value) {
    commit('setPropertyIds', value);
  },
  setTop30PropertiesIds({ commit }, value) {
    commit('setTop30PropertiesIds', value);
  },
  setFeaturePropertiesIds({ commit }, value) {
    commit('setFeaturePropertiesIds', value);
  },
  setTrackingDetails({ commit }, value) {
    commit('setTrackingDetails', value);
  },
  setPropertyTypeKey({ commit }, value) {
    commit('setPropertyTypeKey', value);
  },
  setNumberResults({ commit }, value) {
    commit('setNumberResults', value);
  },
  setPageType({ commit }, value) {
    commit('setPageType', value);
  },
  setProductId({ commit }, value) {
    commit('setProductId', value);
  },
  setPropertyName({ commit }, value) {
    commit('setPropertyName', value);
  },
  setPropertyDepositPercent({ commit }, value) {
    commit('setPropertyDepositPercent', value);
  },
  setPropertyClass({ commit }, value) {
    commit('setPropertyClass', value);
  },
  setPropertyCurrency({ commit }, value) {
    commit('setPropertyCurrency', value);
  },
};

export const getters = {
  getPropertyIds(state) {
    return state.trackingData.propertyIds;
  },
  getTop30PropertiesIds(state) {
    return state.trackingData.top30PropertiesIds;
  },
  getFeaturePropertiesIds(state) {
    return state.trackingData.featurePropertiesIds;
  },
  getTrackingDetails(state) {
    return state.trackingData.trackingDetails;
  },
  getPropertyTypeKey(state) {
    return state.trackingData.propertyTypeKey;
  },
  getNumberResults(state) {
    return state.trackingData.number_results;
  },
  getPageType(state) {
    return state.trackingData.page_type;
  },
  getProductId(state) {
    return state.trackingData.product_id;
  },
  getPropertyName(state) {
    return state.trackingData.property_name;
  },
  getPropertyDepositPercent(state) {
    return state.trackingData.property_deposit_percent;
  },
  getPropertyClass(state) {
    return state.trackingData.property_class;
  },
  getPropertyCurrency(state) {
    return state.trackingData.property_currency;
  },
};
