import footerConfigs from '~/config/footer';

export default {
  data() {
    return {
      socialNetworks: {
        facebook: {
          icon: 'sn-facebook',
          attrs: {
            href: this.$t('t_HWFACEBOOKURL'),
            title: this.$t('t_JOINUSONFACEBOOK'),
          },
        },
        instagram: {
          icon: 'sn-instagram-circle',
          attrs: {
            href: this.$t('t_HWINSTAGRAMURL'),
            title: this.$t('t_HWINSTAGRAM'),
          },
        },
        twitter: {
          icon: 'sn-twitter-circle',
          attrs: {
            href: this.$t('t_HWTWITTERURL'),
            title: this.$t('t_FOLLOWUSONTWITTER'),
          },
        },
        youtube: {
          icon: 'sn-youtube-circle',
          attrs: {
            href: this.$t('t_HWYOUTUBEURL'),
            title: this.$t('t_WATCHUSONYOUTUBE'),
          },
        },
        pinterest: {
          icon: 'sn-pinterest',
          attrs: {
            href: this.$t('t_HWPINTERESTURL'),
            title: this.$t('t_HWPINTERESTTITLE'),
          },
        },
        tiktok: {
          icon: 'sn-tiktok-circle',
          attrs: {
            href: this.$t('t_HWTIKTOKURL'),
            title: this.$t('t_HWTIKTOKTITLE'),
          },
        },
        weibo: {
          icon: 'sn-weibo',
          attrs: {
            href: this.$t('t_HWWEIBOURL'),
            title: this.$t('t_JOINUSONWEIBO'),
          },
        },
        naver: {
          icon: 'sn-naver',
          attrs: {
            href: this.$t('t_HWNAVERURL'),
            title: this.$t('t_NAVERBLOG'),
          },
        },
        naverblog: {
          icon: 'sn-naver-post',
          attrs: {
            href: this.$t('t_HWNAVERPOSTURL'),
            title: this.$t('t_NAVERBLOG'),
          },
        },
      },
    };
  },
  computed: {
    getSocialNetworks() {
      const language = this.$store.state.session.language.lang;

      return footerConfigs.socialNetworks[language].reduce((socialNetworks, snLabel) => {
        socialNetworks.push(this.socialNetworks[snLabel]);
        return socialNetworks;
      }, []);
    },
  },
};
