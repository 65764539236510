export default {
  data() {
    const web_redirectGeneric = this.$optimizely.isFeatureEnabled('web_redirectVouchersCoupons');

    return {
      legalLinks: [
        {
          text: this.$t('t_FOOTERDATAPROTECTIONNOTICE'),
          attrs: {
            href: this.$url.getGenericPageUrl('securityprivacy'),
          },
        },
        {
          text: this.$t('t_FOOTERTERMSANDLEGAL'),
          attrs: {
            href: this.$url.getGenericPageUrl('hosteltermsandconditions'),
          },
        },
        {
          text: this.$t('t_FOOTERLINKCOUPONS'),
          attrs: {
            href: web_redirectGeneric
              ? this.$url.getGenericPageUrl('coupons-terms-conditions')
              : '/coupons-terms-conditions',
          },
        },
        {
          text: 'Vouchers',
          attrs: {
            href: web_redirectGeneric
              ? this.$url.getGenericPageUrl('vouchertermsandconditions')
              : '/vouchertermsandconditions',
          },
        },
        {
          text: this.$t('t_FOOTERWEBSITETESTING'),
          attrs: {
            href: this.$url.getGenericPageUrl('website-testing'),
          },
        },
        {
          text: this.$t('t_FOOTERSITEMAP'),
          attrs: {
            href: this.$url.getGenericPageUrl('sitemap'),
          },
        },
      ],
    };
  },
};
