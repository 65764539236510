<template>
  <div class="container">
    <error404 v-if="error.statusCode == notFound" />
    <error500 v-if="error.statusCode == internalServerError" />
  </div>
</template>

<script>
import error500 from '~/components/errorPage/500';
import error404 from '~/components/errorPage/404';
import { getHttpErrors } from '~/lib/http';

export default {
  name: 'ErrorPage',

  layout: 'default',

  components: {
    error500,
    error404,
  },

  head() {
    return {
      htmlAttrs: { lang: this.$store.state.session.language.lang },
      meta: [
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },

  data() {
    return {
      internalServerError: getHttpErrors('INTERNAL_SERVER_ERROR'),
      notFound: getHttpErrors('NOT_FOUND'),
    };
  },

  props: ['error'],
};
</script>

<style lang="scss" scoped>
.main .wrapper {
  background: #fff;
  overflow: hidden;
}
</style>
