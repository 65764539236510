const protocol = `${process.env.BASE_URL_PROTOCOL}://`;
const localeDomains = {
  cs: protocol + process.env.BASE_URL_CS,
  da: protocol + process.env.BASE_URL_DA,
  de: protocol + process.env.BASE_URL_DE,
  en: protocol + process.env.BASE_URL_EN,
  es: protocol + process.env.BASE_URL_ES,
  fi: protocol + process.env.BASE_URL_FI,
  fr: protocol + process.env.BASE_URL_FR,
  it: protocol + process.env.BASE_URL_IT,
  ja: protocol + process.env.BASE_URL_JA,
  ko: protocol + process.env.BASE_URL_KO,
  nb: protocol + process.env.BASE_URL_NB,
  nl: protocol + process.env.BASE_URL_NL,
  pl: protocol + process.env.BASE_URL_PL,
  br: protocol + process.env.BASE_URL_BR,
  pt: protocol + process.env.BASE_URL_PT,
  ru: protocol + process.env.BASE_URL_RU,
  sv: protocol + process.env.BASE_URL_SV,
  tr: protocol + process.env.BASE_URL_TR,
  zh: protocol + process.env.BASE_URL_ZH,
};

export default localeDomains;
