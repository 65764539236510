import { LANGUAGES, DEFAULT_LANGUAGE } from '~/config/languages';
import { PROPERTY_TYPES, PROPERTY_TYPE_DEFAULT } from '~/config/property-types';
import { commonValues } from '~/config/common';
import { UrlBuilder } from '~/lib/url';
import { parse, serialize } from '~/lib/cookie';
import { setHeader } from '~/lib/http';
import { setCookie } from '~/lib/dom';

import t_SLUGPROPERTYTYPEHOSTELS from '~/locales/all/t_SLUGPROPERTYTYPEHOSTELS';
import t_SLUGPROPERTYTYPEHOTELS from '~/locales/all/t_SLUGPROPERTYTYPEHOTELS';
import t_SLUGPROPERTYTYPEBNB from '~/locales/all/t_SLUGPROPERTYTYPEBNB';

export class RouteManager {
  constructor() {
    this.defaultLanguage = DEFAULT_LANGUAGE.lang;
    this.default404 = '404';
    this.defaultResponse = {
      id: null,
      name: '404',
      urlFriendlyName: '404',
    };
    this.enrichedErrorResponse = (description, req) => {
      return {
        status: 404,
        url: req?.url,
        referer: req?.headers?.referer,
        userAgent: req?.headers['user-agent'],
        error: { message: { description } },
      };
    };
  }

  getUrlData(
    propertyTypeUrl = null,
    continentUrl = null,
    countryUrl = null,
    cityUrl = null,
    cityPageNumber = null,
    propertyIdUrl = null,
    propertyNameUrl = null,
    areaUrl = null,
    regionUrl = null,
    districtUrl = null,
    districtPageNumber = null,
    chainUrl = null,
    cityFilterUrl = null,
    districtFilterUrl = null
  ) {
    return {
      propertyType: propertyTypeUrl,
      continent: continentUrl,
      country: countryUrl,
      city: cityUrl,
      cityPageNumber: cityPageNumber,
      propertyId: propertyIdUrl,
      propertyName: propertyNameUrl,
      area: areaUrl,
      region: regionUrl,
      district: districtUrl,
      districtPageNumber: districtPageNumber,
      chain: chainUrl,
      cityFilterUrl,
      districtFilterUrl,
    };
  }

  redirectRoute(app, store, params, query) {
    if (!process.server) {
      return;
    }

    if (JSON.stringify(params) !== JSON.stringify(store.state.route.data)) {
      store.commit('route/setRoute', params);
      let redirectUrl = '/';
      let actualUrl = app.router.currentRoute.path;
      const actualQuery = app.router.currentRoute.query;

      // @TODO: For some weird reason, app.router.currentRoute.path sometimes just brings the following string
      if (actualUrl === '/https;') {
        return;
      }

      redirectUrl += Object.keys(params)
        .filter((key) => params[key] !== null)
        .map((key) => {
          if (key === 'area') {
            return `a/${params[key]}`;
          }

          if (key === 'region') {
            return `r/${params[key]}`;
          }

          if (key === 'district') {
            return `d/${params[key]}`;
          }

          if (key === 'propertyId') {
            return `p/${params[key]}`;
          }
          if (key === 'chain') {
            return `${commonValues.HOSTELCHAINURL}/${params[key]}`;
          }
          if (key === 'cityFilterUrl') {
            return `f/${params[key]}`;
          }
          if (key === 'districtFilterUrl') {
            return `f/${params[key]}`;
          }
          return params[key];
        })
        .join('/');
      redirectUrl = new UrlBuilder().addSlashOnEnd(redirectUrl).replace('//', '/');
      actualUrl = new UrlBuilder().addSlashOnEnd(actualUrl).replace('//', '/');
      if (query && Object.keys(query).length > 0) {
        redirectUrl = new UrlBuilder(redirectUrl).buildQueryDecoded(query);
      }
      if (actualQuery && Object.keys(actualQuery).length > 0) {
        actualUrl = new UrlBuilder(actualUrl).buildQueryDecoded(actualQuery);
      }

      if (redirectUrl) {
        const matcher = new RegExp(`^${this.default404}/?$`, 'g');
        if (matcher.test(redirectUrl)) {
          app.context.error({ statusCode: 404 });
          return;
        }

        if (process.server && redirectUrl !== decodeURIComponent(actualUrl)) {
          app.context.redirect(redirectUrl);
        }
      }
    }
  }

  redirectEntityLevelUp(app, store, urlToRedirectTo, query) {
    if (!process.server) {
      return;
    }

    const isDevMode = query?.dev === '1';

    if (isDevMode) {
      console.error(`Redirecting to: ${urlToRedirectTo} should have occur here`);
      return;
    }

    app.context.redirect(urlToRedirectTo);
  }

  validateLanguageParam(lang, store) {
    if (!lang) {
      return null;
    }
    const currentLang = store.state.session.language.lang;
    let langObj = store.state.session.language;

    if (lang !== currentLang) {
      langObj = LANGUAGES.find((language) => language.lang === currentLang);

      lang = new UrlBuilder(lang).replaceUrlCharacters();
      const langFind = LANGUAGES.find((language) => language.lang === lang);

      if (langFind?.lang) {
        langObj = langFind;
      }

      store.commit('session/setLanguage', langObj);
    }

    return langObj.lang;
  }

  validatePropertyTypeParam(propertyType, store) {
    const currentLangCode = store.state.session.language.code;
    let actualPropertyTypeObj = PROPERTY_TYPE_DEFAULT;

    const transcodesPropertyTypes = {
      t_SLUGPROPERTYTYPEHOSTELS: t_SLUGPROPERTYTYPEHOSTELS[currentLangCode],
      t_SLUGPROPERTYTYPEHOTELS: t_SLUGPROPERTYTYPEHOTELS[currentLangCode],
      t_SLUGPROPERTYTYPEBNB: t_SLUGPROPERTYTYPEBNB[currentLangCode],
    };

    const propertyTypeIsValid =
      Object.values(t_SLUGPROPERTYTYPEHOSTELS).includes(propertyType) ||
      Object.values(t_SLUGPROPERTYTYPEHOTELS).includes(propertyType) ||
      Object.values(t_SLUGPROPERTYTYPEBNB).includes(propertyType);

    propertyType = propertyTypeIsValid ? propertyType : transcodesPropertyTypes[actualPropertyTypeObj.slug];

    propertyType = new UrlBuilder(propertyType).replaceUrlCharacters();

    let propertyResult = {
      key: this.default404,
      slug: this.default404,
    };

    let currentPropertyTypeSlug = null;
    let currentPropertyTypeKey = null;
    Object.keys(transcodesPropertyTypes).forEach((key, value) => {
      if (transcodesPropertyTypes[key] === propertyType) {
        currentPropertyTypeSlug = transcodesPropertyTypes[key];
        actualPropertyTypeObj = PROPERTY_TYPES.find((properties) => properties.slug === key);
        currentPropertyTypeKey = actualPropertyTypeObj && actualPropertyTypeObj.key ? actualPropertyTypeObj.key : null;
      }
    });

    if (currentPropertyTypeKey && currentPropertyTypeSlug) {
      store.commit('route/setPropertyTypeKey', currentPropertyTypeKey);
      return {
        id: actualPropertyTypeObj.id,
        type: actualPropertyTypeObj.type,
        key: currentPropertyTypeKey,
        transcode: actualPropertyTypeObj.transcode,
        transcode_s: actualPropertyTypeObj.transcode_s,
        slug: currentPropertyTypeSlug,
        limit: actualPropertyTypeObj.limit,
      };
    }

    let propertyCycleResult = this.cycleThroughTranscodesFilesToCheckPropertyTypeUrl(currentLangCode, propertyType);
    if (propertyCycleResult && propertyCycleResult.key && propertyCycleResult.slug) {
      store.commit('route/setPropertyTypeKey', propertyCycleResult.key);
      return {
        id: actualPropertyTypeObj.id,
        type: actualPropertyTypeObj.type,
        key: propertyCycleResult.key,
        transcode: actualPropertyTypeObj.transcode,
        transcode_s: actualPropertyTypeObj.transcode_s,
        slug: propertyCycleResult.slug,
        limit: propertyCycleResult.limit,
      };
    }

    return propertyResult;
  }

  cycleThroughTranscodesFilesToCheckPropertyTypeUrl(langCode, propertyType) {
    let transcodeSelected = null;
    let fileSelected = null;

    const transcodesFiles = {
      t_SLUGPROPERTYTYPEHOSTELS: t_SLUGPROPERTYTYPEHOSTELS,
      t_SLUGPROPERTYTYPEHOTELS: t_SLUGPROPERTYTYPEHOTELS,
      t_SLUGPROPERTYTYPEBNB: t_SLUGPROPERTYTYPEBNB,
    };

    Object.keys(transcodesFiles).find(function (fileName) {
      Object.keys(transcodesFiles[fileName]).find(function (transcode) {
        if (propertyType === transcodesFiles[fileName][transcode]) {
          transcodeSelected = transcodesFiles[fileName][langCode];
          fileSelected = fileName;
        }
      });
    });

    if (fileSelected && transcodeSelected) {
      const propertySlugObj = PROPERTY_TYPES.find((properties) => properties.slug === fileSelected);
      if (propertySlugObj && propertySlugObj.key) {
        return {
          key: propertySlugObj.key,
          slug: transcodeSelected,
        };
      }
    }
  }

  async validateContinentParam(lang, currency, propertyType, continent, app, store, req, res) {
    const continentsServices = await app.$api.getSpApi().getServices('continents');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    let continentsObj = {};

    try {
      continentsObj = await Promise.resolve(
        continentsServices.getContinentByName(lang, currency, propertyType, continent)
      );
    } catch (error) {
      continentsObj = this.defaultResponse;
      const description = `Continent ${continent} not found`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (!continentsObj || continentsObj.id === null) {
      const description = `No continent ${continent} found`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (continentsObj && continentsObj.id) {
      const cookieObj = {
        continentId: continentsObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (continentsObj && continentsObj.id) {
      try {
        continentsObj.locationTranslations = await Promise.resolve(
          translatorServices.getContinentLocationTranslatedById(lang, continentsObj.id)
        );
      } catch (error) {
        const description = `Continent (${continent}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return continentsObj;
  }

  async validateCountryParam(lang, currency, propertyType, country, app, store, req, res) {
    const countriesServices = await app.$api.getSpApi().getServices('countries');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    let countryObj = {};

    try {
      countryObj = await Promise.resolve(countriesServices.getCountryByName(lang, currency, propertyType, country));
    } catch (error) {
      countryObj = this.defaultResponse;
      const description = `Country ${country} not found`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (!countryObj || countryObj.id === null) {
      const description = `No country ${country} found`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (countryObj && countryObj.id !== null) {
      const cookieObj = {
        continentId: countryObj.continentId,
        countryId: countryObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (countryObj && countryObj.id) {
      try {
        countryObj.locationTranslations = await Promise.resolve(
          translatorServices.getCountryLocationTranslatedById(lang, countryObj.continentId, countryObj.id)
        );
      } catch (error) {
        const description = `Country (${country}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return countryObj;
  }

  async validateAreaParam(lang, currency, propertyType, continent, country, area, app, store, req, res, requestType) {
    const areasServices = await app.$api.getSpApi().getServices('areas');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    let areaObj = {};
    try {
      areaObj = await Promise.resolve(
        areasServices.getAreaByName(lang, currency, propertyType, continent, country, area, requestType)
      );
    } catch (error) {
      areaObj = this.defaultResponse;
      const description = `Area ${area} not found for country ${country}`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (!areaObj || areaObj.id === null) {
      const description = `No area ${area} found in ${country}`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (areaObj && areaObj.id !== null) {
      const cookieObj = {
        continentId: areaObj.continentId,
        countryId: areaObj.countryId,
        areaId: areaObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (areaObj && areaObj.id) {
      try {
        areaObj.locationTranslations = await Promise.resolve(
          translatorServices.getAreaLocationTranslatedById(lang, areaObj.continentId, areaObj.countryId, areaObj.id)
        );
      } catch (error) {
        const description = `Area (${area}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return areaObj;
  }

  async validateRegionParam(
    lang,
    currency,
    propertyType,
    continent,
    country,
    region,
    app,
    store,
    req,
    res,
    requestType
  ) {
    const regionsServices = await app.$api.getSpApi().getServices('areas');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    let regionObj = {};

    try {
      regionObj = await Promise.resolve(
        regionsServices.getAreaByName(lang, currency, propertyType, continent, country, region, requestType)
      );
    } catch (error) {
      regionObj = this.defaultResponse;
      const description = `Region ${region} not found for country ${country}`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (!regionObj || regionObj.id === null) {
      const description = `No region ${region} found in ${country}`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (regionObj && regionObj.id !== null) {
      const cookieObj = {
        continentId: regionObj.continentId,
        countryId: regionObj.countryId,
        regionId: regionObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (regionObj && regionObj.id) {
      try {
        regionObj.locationTranslations = await Promise.resolve(
          translatorServices.getRegionLocationTranslatedById(
            lang,
            regionObj.continentId,
            regionObj.countryId,
            regionObj.id
          )
        );
      } catch (error) {
        const description = `Region (${region}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return regionObj;
  }

  async validateCityParam(lang, currency, propertyType, country, city, app, store, req, res) {
    let cityObj = {};

    // @TODO: STATICPWA-901 Remove after experiment is done
    const experiment_no_availability_banner = store.$optimizely.isFeatureEnabled('static_ignoreAvailability');

    const citiesServices = await app.$api.getSpApi().getServices('city');
    const translatorServices = await app.$api.getSpApi().getServices('translator');

    const countryEncoded = new UrlBuilder(country).replaceUrlCharacters();
    const cityEncoded = new UrlBuilder(city).replaceUrlCharacters();
    if (countryEncoded && cityEncoded) {
      try {
        const queryParams = {
          ignoreAvailability: experiment_no_availability_banner ? '1' : '0',
        };
        cityObj = await Promise.resolve(
          citiesServices.getCityByCityNameAndCountryName(
            lang,
            currency,
            propertyType,
            countryEncoded,
            cityEncoded,
            queryParams
          )
        );
      } catch (error) {
        cityObj = this.defaultResponse;
        const description = `City ${cityEncoded} not found for country ${countryEncoded}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    if (!cityObj || cityObj.id === null) {
      const description = `No city ${cityEncoded} found in ${countryEncoded}`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (cityObj && cityObj.id !== null) {
      const cookieObj = {
        continentId: cityObj.continentId,
        countryId: cityObj.countryId,
        cityId: cityObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (cityObj && cityObj.continentId && cityObj.countryId && cityObj.id) {
      try {
        cityObj.locationTranslations = await Promise.resolve(
          translatorServices.getFullCityLocationTranslatedById(lang, cityObj.continentId, cityObj.countryId, cityObj.id)
        );
      } catch (error) {
        const description = `City (${cityEncoded}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return cityObj;
  }

  async validateDistrictParam(
    lang,
    currency,
    propertyType,
    continent,
    country,
    city,
    district,
    app,
    store,
    req,
    res,
    page,
    limit
  ) {
    const districtsServices = await app.$api.getSpApi().getServices('districts');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    let districtObj = {};

    const experiment_new_sort_order = store.$optimizely.isFeatureEnabled('web_newSortOrder');
    const experiment_no_availability_banner = store.$optimizely.isFeatureEnabled('static_ignoreAvailability');

    try {
      districtObj = await Promise.resolve(
        districtsServices.getDistrictByName(
          lang,
          currency,
          propertyType,
          continent,
          country,
          city,
          district,
          page,
          limit,
          0,
          {
            ignoreAvailability: experiment_no_availability_banner ? 1 : 0,
            origin: 'spapi',
            v: experiment_new_sort_order ? 'variation' : 'control',
          },
          {
            'User-Agent': req?.headers['user-agent'],
          }
        )
      );
    } catch (error) {
      districtObj = this.defaultResponse;
      const description = `District ${district} not found for city ${city}`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (!districtObj || districtObj.id === null) {
      const description = `No district ${district} found in ${city}`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (districtObj && districtObj.id !== null) {
      const cookieObj = {
        continentId: districtObj.continentId,
        countryId: districtObj.countryId,
        cityId: districtObj.cityId,
        districtId: districtObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    if (districtObj && districtObj.id) {
      try {
        districtObj.locationTranslations = await Promise.resolve(
          translatorServices.getDistrictLocationTranslatedById(
            lang,
            districtObj.continentId,
            districtObj.countryId,
            districtObj.cityId,
            districtObj.id
          )
        );
      } catch (error) {
        const description = `District (${district}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return districtObj;
  }

  async validatePropertyParam(lang, property, app, store, req, res) {
    const propertyServices = await app.$api.getSpApi().getServices('property');
    const micrositeServices = await app.$api.getMsApi().getServices('properties');
    const translatorServices = await app.$api.getSpApi().getServices('translator');
    const hwPropertyServices = await app.$api.getHwApi().getServices('properties');
    let propertyObj = {};

    const currentLangTag = store.state.session.language.tag;
    const currentCurrency = store.state.session.currency.code;

    try {
      propertyObj =
        (await Promise.resolve(propertyServices.getPropertyByPropertyId(lang, property))) || this.defaultResponse;
    } catch (error) {
      const description = `Property ${property} not found`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (propertyObj && propertyObj.id === null) {
      const description = `No property ${property} found`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (propertyObj && propertyObj.id !== null) {
      const cookieObj = {
        propertyId: propertyObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }

    let micrositeObj = {};
    try {
      micrositeObj = await Promise.resolve(micrositeServices.getPropertyDetailsByPropertyId(property));
    } catch (error) {
      const description = `No property details fount for ${propertyObj?.id} on MS`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    let hwPropertyObj = {};
    try {
      hwPropertyObj = await Promise.resolve(
        hwPropertyServices.getPropertyDetailsByPropertyId(propertyObj?.id, currentLangTag, currentCurrency)
      );
    } catch (error) {
      const description = `No property details found for ${propertyObj?.id} on HW`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (hwPropertyObj && hwPropertyObj.propertyPrices && propertyObj && propertyObj.id !== null) {
      propertyObj.propertyPrices = hwPropertyObj.propertyPrices;
      propertyObj.lowestPricePerNight = hwPropertyObj.lowestPricePerNight;
      propertyObj.depositPercentage = hwPropertyObj.depositPercentage;
    }

    if (hwPropertyObj?.sustainability) {
      propertyObj.sustainability = hwPropertyObj.sustainability;
    }

    if (propertyObj && propertyObj.id) {
      try {
        propertyObj.locationTranslations = await Promise.resolve(
          translatorServices.getPropertyLocationTranslatedById(lang, propertyObj.id)
        );
      } catch (error) {
        const description = `Property (${property}) location translation not found for lang ${lang}`;
        console.error(this.enrichedErrorResponse(description, req));
      }
    }

    return { ...propertyObj, ...micrositeObj };
  }

  async validateHostelChainParam(lang, currency, hostelChain, app, store, req, res) {
    const brandServices = await app.$api.getSpApi().getServices('brand');

    let hostelChainObj = {};

    try {
      hostelChainObj =
        (await Promise.resolve(brandServices.getHostelChain(lang, currency, hostelChain))) || this.defaultResponse;
    } catch (error) {
      const description = `Hostel Chain ${hostelChain} not found`;
      console.error(this.enrichedErrorResponse(description, req));
    }

    if (hostelChainObj && hostelChainObj.id === null) {
      const description = `No Hostel Chain ${hostelChain} found`;
      throw { error: this.enrichedErrorResponse(description, req) };
    }

    if (hostelChainObj && hostelChainObj.id !== null) {
      const cookieObj = {
        chainId: hostelChainObj.id,
      };
      this.setRouteCookie(app, req, res, cookieObj);
    }
    const hostelChainResult = hostelChainObj;
    return hostelChainResult;
  }

  readRouteCookie(req) {
    if (process.server) {
      const cookies = parse(req.headers.cookie || '');
      const routeOjb = cookies['spwaroute'];

      if (routeOjb !== undefined) {
        return JSON.parse(routeOjb);
      }
    }
  }

  setRouteCookie(app, req, res, values) {
    let cookieData = {};
    const cookieName = 'spwaroute';
    const cookiesOptions = {
      domain: app.$env.COOKIE_DOMAIN,
      expires: 0,
      path: '/',
      SameSite: 'Strict',
      secure: app.$env.COOKIE_SECURE !== 'false',
    };

    let cookies = process.server ? parse(req.headers.cookie || '') : parse(document.cookie || '');
    let routeOjb = cookies[cookieName];
    if (routeOjb !== undefined) {
      cookieData = JSON.parse(routeOjb);
    }

    cookieData = { ...cookieData, ...values };

    process.server
      ? setHeader(res, 'Set-Cookie', serialize(cookieName, JSON.stringify(cookieData), cookiesOptions))
      : setCookie(serialize(cookieName, JSON.stringify(cookieData), cookiesOptions));
  }
}

export default (context, inject) => {
  const routeManager = new RouteManager();
  inject('routeManager', routeManager);
};
