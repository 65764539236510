import { LANGUAGES } from '~/config/languages';

const acceptedFields = ['crossorigin', 'href', 'hreflang', 'media', 'referrerpolicy', 'rel', 'sizes', 'title', 'type'];

export default class LinkTag {
  constructor() {
    this.data = [];
  }

  /**
   * @return {array} data
   */
  getLinks() {
    return this.data;
  }

  getLinksByLang() {
    if (!this.data || !this.data.length) {
      return;
    }

    const allLanguages = LANGUAGES.reduce((prevArray, langObj) => {
      prevArray.push(langObj.tag);
      return prevArray;
    }, []);

    return this.data.reduce((prevVal, linkInfo) => {
      if (linkInfo.hreflang && allLanguages.includes(linkInfo.hreflang)) {
        prevVal[linkInfo.hreflang] = linkInfo.href;
      }
      return prevVal;
    }, {});
  }

  /**
   * @param {array} data
   */
  addLinks(data) {
    if (!data || !data.length) {
      return;
    }

    data.forEach((input) => {
      const newData = {};
      const fieldsToGet = Object.keys(input).filter((k) => acceptedFields.includes(k));
      fieldsToGet.forEach((k) => (newData[k] = input[k]));
      if (Object.keys(newData).length) {
        this.data.push(newData);
      }
    });
  }

  toJSON() {
    return { ...this };
  }
}
