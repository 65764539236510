<template>
  <div class="page-container" @mousemove="mouseMove">
    <div class="wrapper">
      <nav-bar class="nav" />
    </div>

    <nyan-cat :enabled="nyanCat" />

    <main class="content-wrapper">
      <div class="content">
        <div class="error-img">
          <img src="/st/images/404-cat.svg" :alt="$t('t_ERROR404TITLE')" :title="$t('t_ERROR404TITLE')" />
          <div class="lightbulb" @click="handleLightBulbClick" @keypress.enter="handleLightBulbClick" />
          <div class="eye" id="left-eye"></div>
          <div class="eye" id="right-eye"></div>
        </div>

        <div class="error-text">
          <h1>{{ $t('t_ERROR404TITLE') }}</h1>
          <h2>{{ $t('t_ERROR404TEXT') }}</h2>

          <wds-button-ghost
            type="button"
            class="error-button"
            :reversed="true"
            :text="$t('t_ERROR404BUTTON')"
            icon-start="hostel"
            rel="noopener"
            @click="redirectPage"
            @keyup.enter="redirectPage"
          />
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import NavBar from '~/components/common/NavBar';
import Footer from '~/components/common/Footer';
import { WdsButtonGhost } from '@wds/components';
import { setHref } from '~/lib/dom';
import NyanCat from '~/components/errorPage/404/nyanCat/index.vue';

export default {
  name: 'error404',

  components: {
    Footer,
    NavBar,
    WdsButtonGhost,
    NyanCat,
  },

  data() {
    return {
      lightBulbClicks: 0,
      nyanCat: false,
    };
  },

  mounted() {
    this.trackPageViews();
    this.setupEyes();
  },

  computed: {
    getHwUrl() {
      return `${this.$url.getHomepageUrl()}`;
    },
  },

  methods: {
    async trackPageViews() {
      this.tracking = await this.$tracking.GenericPage();

      const trackingInfo = {
        gtm: {
          gtmPageName: 'error404Page',
        },
        segment: {
          event_name: '404',
          page_type: '404',
        },
      };

      await this.tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);
    },

    sendTrackingEvent() {
      const trackingInfo = {
        gtm: {
          parameter1: 'easterEgg',
        },
        segment: {
          event_name: 'Error 404 Page Event',
          action: 'Easter Egg found',
        },
      };

      this.tracking?.onEasterEggFound(trackingInfo.gtm, trackingInfo.segment);
    },

    setupEyes() {
      this.eye = document.querySelectorAll('.eye');
    },

    mouseMove(event) {
      this.eye.forEach(function (elem) {
        let x = elem.getBoundingClientRect().left + elem.clientWidth / 2;
        let y = elem.getBoundingClientRect().top + elem.clientHeight / 2;
        let radian = Math.atan2(event.pageX - x, event.pageY - y);
        let rotate = radian * (180 / Math.PI) * -1 + 270;
        elem.style.transform = 'rotate(' + rotate + 'deg)';
      });
    },

    handleLightBulbClick() {
      this.lightBulbClicks++;

      if (this.lightBulbClicks === 9) {
        this.nyanCat = !this.nyanCat;
        this.lightBulbClicks = 0;
        this.sendTrackingEvent();
      }
    },

    redirectPage() {
      setTimeout(() => {
        setHref(this.getHwUrl);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  margin: 0;
  grid-template-rows: auto auto 1fr auto;
  position: relative;

  .wrapper {
    padding: $wds-spacing-s wds-rem(40px);
    width: 100%;
  }

  .content-wrapper {
    background-color: $wds-color-ink-darker;
    padding: 0 0 wds-rem(70px) 0;
    position: relative;
    width: 100%;
    clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);
    z-index: 10;

    .content {
      padding: $wds-spacing-m;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 0;
      grid-auto-flow: row;
      grid-template-areas:
        'error-img'
        'error-text';

      @include tablet-large {
        padding: $wds-spacing-m;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'error-text error-img';
        max-width: wds-rem(960px);
        margin: 0 auto;
      }

      .error-img {
        grid-area: error-img;
        width: wds-rem(290px);
        margin: wds-rem(-70px) auto $wds-spacing-m;
        position: relative;

        @include tablet-large {
          width: wds-rem(450px);
          margin: wds-rem(-100px) auto $wds-spacing-m;
        }

        #left-eye,
        #right-eye,
        .lightbulb {
          display: none;
        }

        @include desktop {
          #left-eye,
          #right-eye {
            position: absolute;
            display: inline-block;
            border-radius: 50%;
            height: wds-rem(22px);
            width: wds-rem(22px);
            background: $wds-color-sunset-light;
          }

          #right-eye {
            bottom: wds-rem(118px);
            right: wds-rem(102px);
          }

          #left-eye {
            bottom: wds-rem(95px);
            right: wds-rem(125px);
          }

          #right-eye:after {
            position: absolute;
            bottom: wds-rem(6px);
            right: wds-rem(9px);
            width: wds-rem(13px);
            height: wds-rem(15px);
            background: $wds-color-ink-darker;
            border-radius: 50%;
            content: ' ';
          }

          #left-eye:after {
            position: absolute;
            bottom: wds-rem(6px);
            right: wds-rem(9px);
            width: wds-rem(13px);
            height: wds-rem(15px);
            background: $wds-color-ink-darker;
            border-radius: 50%;
            content: ' ';
          }

          .lightbulb {
            position: absolute;
            top: wds-rem(192px);
            left: wds-rem(245px);
            background: transparent;
            width: wds-rem(31px);
            height: wds-rem(17px);
            display: inline-block;
          }
        }
      }

      .error-text {
        grid-area: error-text;

        h1 {
          margin-bottom: $wds-spacing-s;
          color: $wds-color-sunset-light;

          @include title-2-bld;
        }

        h2 {
          margin-bottom: $wds-spacing-s;
          color: $wds-color-white;

          @include title-5-bld;
        }

        @include tablet-large {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h1 {
            @include title-1-bld;
          }
        }
      }

      .error-button {
        margin-top: $wds-spacing-xl;
        width: 100%;
      }
    }

    @include tablet-large {
      padding: 0 0 wds-rem(100px) 0;
    }
  }

  footer {
    margin-top: wds-rem(-70px);

    @include tablet {
      margin-top: wds-rem(-80px);
    }
  }
}
</style>
