export class UrlBuilder {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  buildQuery(queryParams) {
    return (
      this.baseURL +
      '?' +
      Object.entries(queryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    );
  }

  buildQueryDecoded(queryParams) {
    return (
      this.baseURL +
      '?' +
      Object.entries(queryParams)
        .map(([key, value]) => `${key}=${decodeURIComponent(value)}`)
        .join('&')
    );
  }

  replaceUrlCharacters() {
    return this.baseURL ? this.baseURL.replace(/[\W_]+/g, '-').toLowerCase() : '';
  }

  addSlashOnEnd(value) {
    const regex = new RegExp('/$');
    return !value || regex.test(value) ? value : `${value}/`;
  }

  addProtocol(protocol = 'https') {
    if (new RegExp(`^${protocol}`).test(this.baseURL)) {
      return this.baseURL;
    }

    return protocol.toLowerCase() + '://' + this.baseURL.trimStart('/');
  }
}
