import { GA_ACCOUNTS } from '~/config/analytics';

export default ({ app }, inject) => {
  // Client site only
  if (process.server) return;

  if (app.$optimizely.isFeatureEnabled('web_googleTagManager')) {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    inject('gtag', gtag);

    app.$gtag('js', new Date());

    GA_ACCOUNTS.forEach(({ ID, ATTR }) => {
      app.$gtag('config', ID, ATTR || {});
    });
  }
};
