<template>
  <div class="nyan-container" :class="enabled ? 'on' : ''" tabindex="-1">
    <div class="gradient-top" />

    <div class="stars">
      <div class="stars-back"></div>
      <div class="stars-middle"></div>
      <div class="stars-front"></div>
    </div>
    <div class="clouds"></div>

    <div class="nyan-wrapper">
      <div class="nyan-cat-wrapper cat1">
        <img
          src="/st/images/nyan-cat.png"
          width="150"
          height="84"
          class="nyan-cat"
          :alt="$t('t_ERROR404TITLE')"
          :title="$t('t_ERROR404TITLE')"
        />
        <div class="nyan-rainbow" />
      </div>

      <div class="nyan-cat-wrapper cat2">
        <img
          src="/st/images/nyan-cat.png"
          width="200"
          height="111"
          class="nyan-cat"
          :alt="$t('t_ERROR404TITLE')"
          :title="$t('t_ERROR404TITLE')"
        />
        <div class="nyan-rainbow" />
      </div>

      <div class="nyan-cat-wrapper cat3">
        <img
          src="/st/images/nyan-cat.png"
          width="289"
          height="161"
          class="nyan-cat"
          :alt="$t('t_ERROR404TITLE')"
          :title="$t('t_ERROR404TITLE')"
        />
        <div class="nyan-rainbow" />
      </div>
    </div>

    <div class="gradient-bottom" />
  </div>
</template>

<script>
export default {
  name: 'nyanCat',

  props: {
    enabled: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nyan-container {
  transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
  max-height: 0;
  min-height: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;

  .clouds,
  .stars,
  .nyan-cat-wrapper,
  .nyan-cat,
  .nyan-rainbow,
  .nyan-wrapper {
    display: none;
  }

  @include desktop {
    &.on {
      display: block;
      position: relative;
      width: 100%;
      height: wds-rem(400px);
      max-height: wds-rem(400px);
      min-height: wds-rem(400px);
      opacity: 1;
      background: $wds-color-ink-darker;
      margin-bottom: wds-rem(-40px);

      .gradient-top {
        background: linear-gradient(180deg, rgba(18, 20, 23, 1) 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: wds-rem(30px);
        display: block;
        z-index: 5;
        position: relative;
      }

      .gradient-bottom {
        background: linear-gradient(0deg, rgba(18, 20, 23, 1) 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: wds-rem(30px);
        display: block;
        z-index: 5;
        position: absolute;
        bottom: wds-rem(13px);
        transform: skew(0deg, 1deg);
      }

      .clouds {
        background: transparent url('/st/images/clouds.webp') repeat;
        width: wds-rem(10000px);
        height: 100%;
        background-size: wds-rem(1000px) wds-rem(1000px);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 3;
        animation: move-background 150s linear infinite;
        display: block;
      }

      .stars {
        display: block;

        div {
          overflow: hidden;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
          width: 100%;
        }

        .stars-back {
          top: -50%;
          background-image: radial-gradient(2px 2px at 20px 30px, #eee, rgba(0, 0, 0, 0)),
            radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)),
            radial-gradient(1px 1px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
            radial-gradient(2px 2px at 160px 120px, #ddd, rgba(0, 0, 0, 0));
          background-size: wds-rem(300px) wds-rem(300px);
          background-repeat: repeat;
          animation: stars 40s infinite linear;
        }

        .stars-middle {
          background-image: radial-gradient(3px 3px at 50px 160px, #ddd, rgba(0, 0, 0, 0)),
            radial-gradient(2px 2px at 90px 40px, #fff, rgba(0, 0, 0, 0)),
            radial-gradient(2px 2px at 130px 80px, #fff, rgba(0, 0, 0, 0));
          background-size: wds-rem(200px) wds-rem(200px);
          background-repeat: repeat;
          animation: stars 50s infinite linear;
        }
      }

      .nyan-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        opacity: 1;
        z-index: 10;
      }

      .nyan-cat-wrapper.cat1 {
        top: 0;
        width: wds-rem(200px);
        height: wds-rem(84px);
        animation: float 1.5s infinite ease-out, moving-cats 45s infinite linear;
        filter: blur(3px);

        .nyan-cat {
          width: wds-rem(150px);
          height: wds-rem(84px);
        }

        .nyan-rainbow {
          top: wds-rem(27px);
          height: wds-rem(36px);
          right: wds-rem(-1895px);
          animation: rainbow 3s linear infinite;
        }
      }

      .nyan-cat-wrapper.cat2 {
        top: wds-rem(70px);
        width: wds-rem(250px);
        height: wds-rem(111px);
        animation: float 1s infinite ease-out, moving-cats 55s infinite linear;
        filter: blur(1px);

        .nyan-cat {
          width: wds-rem(200px);
          height: wds-rem(111px);
        }

        .nyan-rainbow {
          top: wds-rem(32px);
          height: wds-rem(50px);
          right: wds-rem(-1860px);
          animation: rainbow 2s linear infinite;
        }
      }
      .nyan-cat-wrapper.cat3 {
        top: wds-rem(140px);
        width: wds-rem(389px);
        height: wds-rem(161px);
        animation: float 1s infinite ease-out, moving-cats 50s infinite linear;

        .nyan-cat {
          width: wds-rem(289px);
          height: wds-rem(161px);
        }

        .nyan-rainbow {
          top: wds-rem(52px);
          right: wds-rem(-1800px);
          height: wds-rem(68px);
          animation: rainbow 1s linear infinite;
        }
      }

      .nyan-cat-wrapper {
        position: absolute;
        right: 0;
        display: block;

        .nyan-cat {
          display: block;
          z-index: 3;
          position: relative;
        }

        .nyan-rainbow {
          background: linear-gradient(
              to left,
              rgba(255, 0, 0, 1) 0%,
              rgba(255, 154, 0, 1) 10%,
              rgba(208, 222, 33, 1) 20%,
              rgba(79, 220, 74, 1) 30%,
              rgba(63, 218, 216, 1) 40%,
              rgba(47, 201, 226, 1) 50%,
              rgba(28, 127, 238, 1) 60%,
              rgba(95, 21, 242, 1) 70%,
              rgba(186, 12, 248, 1) 80%,
              rgba(251, 7, 217, 1) 90%,
              rgba(255, 0, 0, 1) 100%
            )
            200% 0/200% 100%;
          display: block;
          z-index: 1;
          position: absolute;
          filter: blur(2px);
          width: wds-rem(2000px);
        }
      }
    }
  }

  @keyframes rainbow {
    to {
      background-position: 0 -200%;
    }
  }

  @keyframes float {
    0% {
      transform: translate(0, 0);
    }

    35% {
      transform: translate(0.25rem, 0.3rem);
    }

    50% {
      transform: translate(0.5rem, 0.6rem);
    }

    80% {
      transform: translate(0.3rem, 0.25rem);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes move-background {
    from {
      transform: translate3d(0px, 0px, 0px);
    }
    to {
      transform: translate3d(1000px, 0px, 0px);
    }
  }

  @keyframes stars {
    0% {
      left: -100%;
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(2);
    }
    100% {
      left: 100%;
      opacity: 0.5;
      transform: scale(1);
    }
  }

  @keyframes moving-cats {
    0% {
      right: 0;
    }

    10% {
      right: 25%;
    }

    25% {
      right: 35%;
    }

    50% {
      right: 40%;
    }

    75% {
      right: 25%;
    }

    100% {
      right: 0;
    }
  }
}
</style>
