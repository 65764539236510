const hex = [];

for (let i = 0; i < 256; i++) {
  hex[i] = (i < 16 ? '0' : '') + i.toString(16);
}

const getRandomBytes = (buf) => {
  if (process.server) {
    require('crypto').randomFillSync(buf);
    return buf;
  }

  if (typeof crypto !== 'undefined') {
    return crypto.getRandomValues(buf);
  }

  return buf;
};

/**
 * Generates a RFC 4122 compliant UUID v4.
 *
 * @return {string}
 *
 * {@link https://gist.github.com/jed/982883#gistcomment-2403369}
 */
export const uuid = () => {
  const buf = getRandomBytes(new Uint8Array(16));

  buf[6] = (buf[6] & 0x0f) | 0x40;
  buf[8] = (buf[8] & 0x3f) | 0x80;

  return (
    hex[buf[0]] +
    hex[buf[1]] +
    hex[buf[2]] +
    hex[buf[3]] +
    '-' +
    hex[buf[4]] +
    hex[buf[5]] +
    '-' +
    hex[buf[6]] +
    hex[buf[7]] +
    '-' +
    hex[buf[8]] +
    hex[buf[9]] +
    '-' +
    hex[buf[10]] +
    hex[buf[11]] +
    hex[buf[12]] +
    hex[buf[13]] +
    hex[buf[14]] +
    hex[buf[15]]
  );
};
