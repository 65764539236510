import { createInstance, enums, logging, setLogger, setLogLevel } from '@optimizely/optimizely-sdk';
import { parse, serialize } from '~/lib/cookie';
import { setHeader } from '~/lib/http';
import { uuid } from '~/lib/crypto';

const OPTIMIZELY_COOKIE_AGE = 63072000; // 2 years

// Wrapper wraps the Optimizely SDK with a convenient interface that injects the required user attributes.
export const wrapper = (optimizely, store) => ({
  optimizely: optimizely,
  activate: (experimentKey, customAttributes) =>
    optimizely.activate(experimentKey, store.state.testing.anonymousId, {
      ...store.getters['testing/attributes'],
      ...customAttributes,
    }),
  isFeatureEnabled: (featureKey, customAttributes) => {
    const [key, status] = store.state.testing.anonymousId.split('|');

    if (key === featureKey) {
      return status.toLowerCase() === 'on';
    }

    return optimizely.isFeatureEnabled(featureKey, store.state.testing.anonymousId, {
      ...store.getters['testing/attributes'],
      ...customAttributes,
    });
  },
  isFeatureListEnabled: (featureList = []) => {
    const customFeatures = store.state.testing.anonymousId.split(',').reduce((prevValue, curVal) => {
      const [key, status] = curVal.split('|');
      if (!status) {
        return prevValue;
      }
      prevValue[key] = status.toLowerCase() === 'on';
      return prevValue;
    }, {});

    const enabledFeatures = optimizely.getEnabledFeatures(store.state.testing.anonymousId);

    const featuresToCheck = featureList.map((feature) => {
      return {
        [feature]: customFeatures.hasOwnProperty(feature)
          ? customFeatures[feature]
          : enabledFeatures?.includes(feature),
      };
    });
    return featuresToCheck.reduce((acc, feature) => Object.assign(acc, feature), {});
  },
  getFeatureVariableString: (featureKey, variableKey, customAttributes) =>
    optimizely.getFeatureVariableString(featureKey, variableKey, store.state.testing.anonymousId, {
      ...store.getters['testing/attributes'],
      ...customAttributes,
    }),
  getFeatureVariableInteger: (featureKey, variableKey, customAttributes) =>
    optimizely.getFeatureVariableInteger(featureKey, variableKey, store.state.testing.anonymousId, {
      ...store.getters['testing/attributes'],
      ...customAttributes,
    }),
  getAllFeatureVariables: (featureKey, customAttributes) =>
    optimizely.getAllFeatureVariables(featureKey, store.state.testing.anonymousId, {
      ...store.getters['testing/attributes'],
      ...customAttributes,
    }),
  track: (eventKey, eventTags, customAttributes) =>
    optimizely.track(
      eventKey,
      store.state.testing.anonymousId,
      { ...store.getters['testing/attributes'], ...customAttributes },
      eventTags
    ),
});

// This plugin sends the server-side datafile to the client so that the client state is always
// in sync with the server state on initial render.
export default ({ beforeNuxtRender, nuxtState, store, req, res }, inject) => {
  if (process.server) {
    // Send server datafile to the client.
    beforeNuxtRender(({ nuxtState }) => {
      nuxtState.datafile = process.optimizely.projectConfigManager.datafileManager.currentDatafile;
    });
  }

  if (!process.server) {
    setLogger(nuxtState.env.RELEASE_ENV === 'production' ? null : logging.createLogger());
    setLogLevel(nuxtState.env.RELEASE_ENV === 'production' ? enums.LOG_LEVEL.ERROR : enums.LOG_LEVEL.INFO);
  }

  const client = process.server
    ? process.optimizely
    : createInstance({
        datafile: nuxtState.datafile,
        eventBatchSize: 10,
        eventFlushInterval: 1000,
      });

  if (process.server) {
    const cookies = parse(req?.headers?.cookie || '');
    let anonymousId = cookies['optimizely-aid'];
    if (anonymousId === undefined) {
      anonymousId = uuid();
      setHeader(
        res,
        'Set-Cookie',
        serialize('optimizely-aid', anonymousId, {
          domain: req?.env?.COOKIE_DOMAIN,
          maxAge: OPTIMIZELY_COOKIE_AGE,
          path: '/',
          secure: req?.env?.COOKIE_SECURE !== 'false',
        })
      );
    }
    store.commit('testing/setAnonymousId', anonymousId);
    store.commit('testing/setUserAgent', req?.headers['user-agent'] || 'unknown');
  } else {
    const cookies = parse(document?.cookie || '');
    const anonymousId = cookies['optimizely-aid'];
    store.commit('testing/setAnonymousId', anonymousId);
  }

  inject('optimizely', wrapper(client, store));
};
