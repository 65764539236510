import { CURRENCIES, DEFAULT_CURRENCY } from '~/config/currencies';
import { parse } from '~/lib/cookie';

export default async ({ store, req }) => {
  if (process.server) {
    const { currency } = parse(req.headers.cookie || '');
    const selectedCurrency = CURRENCIES.find((c) => c.code === currency) || DEFAULT_CURRENCY;
    store.commit('session/setCurrency', selectedCurrency);
  }
};
