<template>
  <nav>
    <div class="nav-hw-brand">
      <a :href="getHwUrl" :aria-label="commonValues.HOSTELWORLD" rel="noopener" data-testid="hw-brand-link">
        <wds-logo collection="brand" asset="hw-full" title="Hostelworld" width="203" :lazy="true" />
      </a>
    </div>
    <div class="nav-buttons-wrapper">
      <span class="nav-button">
        <wds-pill-select
          class="login-button"
          icon-start="user-line"
          :aria-label="$t('t_A11YLOGINTOHWACCOUNT')"
          @click="handleLoginClick"
          @keypress.enter="handleLoginClick"
          data-testid="user-button"
        />
      </span>
      <span class="nav-button">
        <wds-pill-select
          class="hamburguer-button"
          icon-start="menu-line"
          :aria-label="$t('t_A11YBURGERMENU')"
          @click="openHamburguerMenu"
          @keypress.enter="openHamburguerMenu"
          data-testid="hamburger-menu-button"
        />
        <div class="hamburguer-popover" v-if="isHamMenuOpen" v-outside.click.stop="closeHamburguerMenu">
          <div class="h-menu-header">
            <div class="h-menu-pickers">
              <div
                class="pill language-picker"
                @click="openLanguagePicker"
                @keypress="openLanguagePicker"
                role="button"
                tabindex="0"
              >
                <wds-pill-select icon-start="globe" :text="getCurrentLanguage.name" />
              </div>
              <div
                class="pill currency-picker"
                @click="openCurrencyPicker"
                @keypress="openCurrencyPicker"
                role="button"
                tabindex="0"
              >
                <wds-pill-select icon-start="money" :text="getCurrentCurrency.code" />
              </div>
            </div>
            <div
              class="h-menu-close"
              @click="closeHamburguerMenu"
              @keypress.enter="closeHamburguerMenu"
              role="button"
              tabindex="0"
            >
              <wds-icon asset="close" />
            </div>
          </div>
          <div class="h-menu-links">
            <hamburguer-menu-links />
          </div>
        </div>
      </span>
    </div>
  </nav>
</template>

<script>
import { WdsLogo, WdsIcon, WdsPillFloating, WdsPillSelect, WdsAccordion, WdsAccordionItem } from '@wds/components';
import HamburguerMenuLinks from '~/components/common/NavBar/sections/HamburguerMenuLinks';
import { mapActions, mapGetters } from 'vuex';
import { commonValues } from '~/config/common';
import { setHref } from '~/lib/dom';

export default {
  name: 'NavBar',

  components: {
    WdsIcon,
    WdsLogo,
    WdsPillFloating,
    WdsPillSelect,
    WdsAccordion,
    WdsAccordionItem,
    HamburguerMenuLinks,
  },

  data() {
    return {
      tracking: null,
      isHamMenuOpen: false,
      commonValues: commonValues,
    };
  },

  computed: {
    ...mapGetters({
      getCurrentLanguage: 'session/getCurrentLanguage',
      getCurrentCurrency: 'session/getCurrentCurrency',
    }),
    getHwUrl() {
      return `${this.$url.getHomepageUrl()}`;
    },
    getLoginUrl() {
      return `${this.$url.getBaseURL()}${commonValues.HOSTELWORLD_LOGIN_URL}`;
    },
  },

  mounted() {
    this.getTracking();
  },

  methods: {
    ...mapActions({
      toggleLanguagePicker: 'session/toggleLanguagePicker',
      toggleCurrencyPicker: 'session/toggleCurrencyPicker',
    }),
    async getTracking() {
      this.tracking = await this.$tracking.NavBarComponent();
    },
    handleLoginClick() {
      this.tracking?.onLoginClick();
      setTimeout(() => {
        setHref(this.getLoginUrl);
      }, 100);
    },
    openHamburguerMenu() {
      if (!this.isHamMenuOpen) {
        this.isHamMenuOpen = true;
      }
    },
    closeHamburguerMenu() {
      if (this.isHamMenuOpen) {
        this.isHamMenuOpen = false;
      }
    },
    openLanguagePicker() {
      this.toggleLanguagePicker(true);
      this.closeHamburguerMenu();
    },
    openCurrencyPicker() {
      this.toggleCurrencyPicker(true);
      this.closeHamburguerMenu();
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: 'brand buttons';
  width: 100%;

  .nav-hw-brand {
    grid-area: brand;
    display: flex;
    align-items: center;
    height: 48px;

    a {
      display: inline-flex;

      &:focus-visible {
        box-shadow: 0 0 0 wds-rem(2px) $wds-color-white, 0 0 0 wds-rem(4px) $wds-color-ink-darker;
        border-radius: wds-rem(6px);
        outline: none;
      }
    }
  }

  .nav-buttons-wrapper {
    grid-area: buttons;
    display: flex;
    gap: $wds-spacing-s;
    justify-content: flex-end;
    align-items: center;
    height: 48px;

    .nav-button {
      position: relative;

      .hamburguer-popover {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        min-height: wds-rem(100px);
        background-color: $wds-color-white;
        z-index: $wds-z-index-popup;
        border-radius: $wds-border-radius-xl;
        box-shadow: $wds-shadow-light-l;

        .h-menu-header {
          padding: $wds-spacing-s $wds-spacing-m;
          border-bottom: 1px solid $wds-color-ink-lightest;
          display: flex;
          align-items: center;

          .h-menu-pickers {
            display: flex;
            flex-grow: 1;

            .pill {
              margin-right: $wds-spacing-s;

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .h-menu-close {
            width: wds-rem(40px);
            text-align: center;
            cursor: pointer;
          }
        }

        .h-menu-links {
          padding: $wds-spacing-s;
        }
      }

      ::v-deep .icon {
        display: inline-flex;
      }
    }
  }

  @include tablet {
    width: 100%;

    .nav-buttons-wrapper .nav-button .hamburguer-popover {
      position: absolute;
      top: wds-rem(44px);
      right: 0;
      left: initial;
      bottom: initial;
      width: wds-rem(320px);
      z-index: $wds-z-index-dropdown;

      .h-menu-header .h-menu-close {
        display: none;
      }
    }
  }

  @include tablet-large {
    .nav-buttons-wrapper .nav-button:first-child {
      margin-left: 0;
    }
  }
}
</style>
