import { LANGUAGES, DEFAULT_LANGUAGE } from '~/config/languages';
import { UrlLanguage } from '~/lib/urlLanguage/index';
import TranscodeInterpolate from '~/models/TranscodeInterpolate';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const setLanguageFromSubdomain = (req, route, store) => {
  const host = `${req.headers.host}${req.url}`;

  const urlLanguage = new UrlLanguage(req, host, route.path, LANGUAGES);
  const locale = urlLanguage.getLanguage() || store.state.session.language;

  if (locale !== store.state.session.language) {
    store.commit('session/setLanguage', locale);
  }
};

export default async ({ req, route, app, store }) => {
  const messages = { [DEFAULT_LANGUAGE.tag]: require(`~/locales/${DEFAULT_LANGUAGE.code}.json`) };
  if (req) {
    setLanguageFromSubdomain(req, route, store);
  }

  const currentLang = store.state.session.language || DEFAULT_LANGUAGE;
  if (currentLang.tag != DEFAULT_LANGUAGE.tag) {
    const module = await import(/* webpackChunkName: "lang-[request]" */ `~/locales/${currentLang.code}.json`);
    const localeMessage = module.default ? module.default : module;
    messages[currentLang.tag] = localeMessage;
  }

  app.i18n = new VueI18n({
    //construction a new VueI18n
    locale: currentLang.tag,
    formatter: new TranscodeInterpolate(),
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages,
  });
};
