import { DEFAULT_LANGUAGE } from '~/config/languages';
import { CURRENCIES, DEFAULT_CURRENCY } from '~/config/currencies';
import { serialize } from '~/lib/cookie';
import { setCookie, reload } from '~/lib/dom';

export const state = /* istanbul ignore next */ () => ({
  entryPoint: '',
  language: DEFAULT_LANGUAGE,
  showLanguagePicker: false,
  currency: DEFAULT_CURRENCY,
  showCurrencyPicker: false,
  sustainability: null,
  showSustainabilityPopup: false,
});

export const mutations = {
  setLanguage(state, { code, tag, lang, url, baseUrl, name, enName }) {
    state.language = { code, tag, lang, url, baseUrl, name, enName };
  },
  setCurrency(state, newCurrency) {
    state.currency = newCurrency;
  },
  setSustainabilityDetails(state, newSustainabilityDetails) {
    state.sustainability = newSustainabilityDetails;
  },
  setEntryPoint(state, entryPoint) {
    state.entryPoint = entryPoint;
  },
  toggleLanguagePicker(state, isVisible) {
    state.showLanguagePicker = isVisible;
  },
  toggleCurrencyPicker(state, isVisible) {
    state.showCurrencyPicker = isVisible;
  },
  toggleSustainabilityPopup(state, isVisible) {
    state.showSustainabilityPopup = isVisible;
  },
};

export const actions = {
  setCurrency({ commit, $store }, newCurrencyCode) {
    const currencyObject = CURRENCIES.find((currency) => currency.code === newCurrencyCode);
    const newCurrencyObject = !!currencyObject ? currencyObject : DEFAULT_CURRENCY;
    const envData = this.$env || $store.$env;
    commit('setCurrency', newCurrencyObject);
    if (!process.server) {
      setCookie(
        serialize('currency', newCurrencyObject.code, {
          domain: envData.COOKIE_DOMAIN,
          path: '/',
          secure: envData.COOKIE_SECURE !== 'false',
          sameSite: 'Strict',
        })
      );
    }
    reload();
  },
  setSustainabilityDetails({ commit }, newSustainabilityDetails) {
    commit('setSustainabilityDetails', newSustainabilityDetails);
    commit('toggleSustainabilityPopup', !!newSustainabilityDetails.tier);
  },
  toggleSustainabilityPopup({ commit }, isVisible) {
    commit('toggleSustainabilityPopup', isVisible);
  },
  toggleLanguagePicker({ commit }, isVisible) {
    commit('toggleLanguagePicker', isVisible);
  },
  toggleCurrencyPicker({ commit }, isVisible) {
    commit('toggleCurrencyPicker', isVisible);
  },
};

export const getters = {
  getCurrentLanguage(state) {
    return state.language;
  },
  getCurrentCurrency(state) {
    return state.currency;
  },
  getCurrentSustainabilityDetails(state) {
    return state.sustainability;
  },
  isLanguagePickerOpen(state) {
    return state.showLanguagePicker;
  },
  isCurrencyPickerOpen(state) {
    return state.showCurrencyPicker;
  },
  isSustainabilityPopupOpen(state) {
    return state.showSustainabilityPopup;
  },
};
