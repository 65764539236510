import { channelAttributionStorage } from '~/lib/LocalStorageAdapter';
import { entryTypes } from '~/config/entryPages';
import consola from 'consola';
import { setCookie } from '~/lib/dom';
import { parse, serialize } from '~/lib/cookie';

export const storageURLParameters = [
  'source',
  'affiliate',
  'aff_userid',
  'network',
  'campaign_id',
  'adgroup_id',
  'criteria_id',
  'creative',
  'locationid',
  'location_physical_id',
  'location_interest_id',
  'ad_position',
  'uniqueclickID',
  'sub_keyword',
  'sub_ad',
  'sub_publisher',
  'gclsrc',
  'auid',
  'keyword',
  'gclid',
  'msclkid',
  'matchtype',
  'adType',
  'hotel_id',
  'utm_content',
  'utm_source',
  'hpa_u_list_id',
];

export const DEFAULT_VALUE = '-1';

export default ({ app, route }, inject) => {
  const REFERRER = 'referrer';
  const ENTRY_PAGE = 'entrypage';

  let ENTRY_BLOG_PAGE = false;

  const CHANNEL_ATTRIBUTION_COOKIE = 'channelAttributionData';

  const getEntryPoint = (value) => {
    return entryTypes[value]?.attribution || entryTypes.OTHER.attribution;
  };

  const getReferrer = (referrer) => {
    if (referrer) {
      if (referrer.match(/^https?:\/\/(.*)\.hostelworld\.com\/blog(.*)(\/|$)/i)) {
        ENTRY_BLOG_PAGE = true;
        return referrer;
      }

      if (!referrer.match(/^https?:\/\/(.*)\.hostelworld\.com/)) {
        if (!referrer.includes('-brand')) {
          const pageLocation = route.path === '/' ? '-brand' : '';
          return referrer + pageLocation;
        }
        return referrer;
      }
      return;
    }
    return DEFAULT_VALUE;
  };

  const isNotTheSamePPC = (newPPC, lastPPC) => {
    const allURLParameters = [...storageURLParameters, REFERRER, ENTRY_PAGE];

    return JSON.stringify(newPPC, allURLParameters) !== JSON.stringify(lastPPC, allURLParameters);
  };

  const getNewPPC = (currentPPCs, t, entrypoint) => {
    const referrer = getReferrer(route.query?.referrer || document.referrer);
    let actualEntryPoint = entrypoint;

    if (!referrer && currentPPCs.length !== 0) {
      return;
    }

    const adType = route.query?.Type || DEFAULT_VALUE;

    const isFromTheBlog = !!(route?.query?.utm_source && route.query?.utm_source === 'blog');

    actualEntryPoint = isFromTheBlog || ENTRY_BLOG_PAGE ? (actualEntryPoint = 'BLOG') : entrypoint;

    const idArray = currentPPCs.map((key) => key.id);
    const lastVisitID = currentPPCs[idArray.indexOf(Math.max(...idArray))]?.id || 0;
    const newPPC = {
      id: lastVisitID + 1,
      t,
      [ENTRY_PAGE]: route.query?.[ENTRY_PAGE] || getEntryPoint(actualEntryPoint),
      [REFERRER]: referrer,
      ...Object.assign(
        {},
        ...storageURLParameters.map((param) => ({
          [param]: route.query?.[param] || DEFAULT_VALUE,
        }))
      ),
      adType,
    };

    return isNotTheSamePPC(newPPC, currentPPCs[currentPPCs.length - 1]) ? [...currentPPCs, newPPC] : currentPPCs;
  };

  inject('storeChannelAttribution', (t = new Date(), entrypoint = '') => {
    try {
      const storage = channelAttributionStorage().getInstance();
      if (!storage) {
        throw new Error('Storage not found.');
      }

      if (t instanceof Date === false) {
        t = new Date();
      }

      const currentPPCs = storage.getItem();
      const newPPC = getNewPPC(currentPPCs || [], t.toISOString().slice(0, 19).replace('T', ' '), entrypoint);

      if (newPPC) {
        storage.replaceItem(newPPC);

        setCookie(
          serialize(CHANNEL_ATTRIBUTION_COOKIE, JSON.stringify(getLastChannelAttribution()), {
            domain: app.$env.COOKIE_DOMAIN,
            expires: 0,
            path: '/',
            sameSite: 'Lax',
            secure: app.$env.COOKIE_SECURE !== 'false',
          })
        );
      }
    } catch (e) {
      consola.error('Unable to set data in local storage: ' + e);
    }
  });

  const getLastChannelAttribution = () => {
    try {
      const storage = channelAttributionStorage().getInstance();
      if (!storage) {
        throw new Error('Storage not found.');
      }
      const channelAttribution = storage.getItem();

      if (channelAttribution && channelAttribution.length > 0) {
        return channelAttribution[channelAttribution.length - 1];
      }
    } catch (e) {
      consola.error('Unable to get data form local storage: ' + e);
    }
  };

  inject('getLastAttributionToQuery', (baseUrl) => {
    const url = new URL(location.href);
    url.hostname = baseUrl;

    const lastAttribution = getLastChannelAttribution();

    if (lastAttribution) {
      Object.keys(lastAttribution).forEach((key) => {
        if (lastAttribution[key] !== DEFAULT_VALUE && key !== 't' && key !== 'id') {
          url.searchParams.set(key, lastAttribution[key]);
        }
      });
    }

    return url;
  });

  const syncChannelAttributionWithCookie = () => {
    try {
      const storage = channelAttributionStorage().getInstance();
      if (!storage) {
        throw new Error('Storage not found.');
      }

      const currentPPCs = storage.getItem() || [];
      const lastChannelAttribution = currentPPCs?.length ? currentPPCs[currentPPCs?.length - 1] : null;
      const cookies = parse(document.cookie || '');
      const channelAttributionCookie = JSON.parse(cookies[CHANNEL_ATTRIBUTION_COOKIE] || null);

      if (
        channelAttributionCookie?.id &&
        (!lastChannelAttribution?.id || isNotTheSamePPC(channelAttributionCookie, lastChannelAttribution))
      ) {
        storage.replaceItem([...currentPPCs, channelAttributionCookie]);
      }
    } catch (e) {
      consola.error(`Unable to set data in local storage: ${e}`);
    }
  };

  if (process.client) {
    syncChannelAttributionWithCookie();
  }
};
