import { GoogleTagManager } from './GoogleTagManager';
import { Segment } from './SegmentJs';
import { instantiate } from '~/lib/lazyLoading';
import consola from 'consola';

export const trackingPlugin = (context) => {
  const gtm = GoogleTagManager(context);
  const segment = Segment(context);

  const loadTracking = async (fileName) => {
    try {
      return await instantiate(`tracking/${fileName}`, { context, gtm, segment });
    } catch (err) {
      consola.error(`Failed to load tracking module tracking/${fileName}`);
    }
  };

  return {
    Layout() {
      return loadTracking('genericPageTracker');
    },
    HomePage() {
      return loadTracking('homepageTracker');
    },
    CityPage() {
      return loadTracking('cityPageTracker');
    },
    PropertyPage() {
      return loadTracking('propertyPageTracker');
    },
    SearchFormComponent() {
      return loadTracking('searchFormTracker');
    },
    NavBarComponent() {
      return loadTracking('navBarTracker');
    },
    PropertyCardComponent() {
      return loadTracking('propertyCardsTracker');
    },
    FaqComponent() {
      return loadTracking('faqTracker');
    },
    GenericPage() {
      return loadTracking('genericPageTracker');
    },
  };
};

/* istanbul ignore next */
export default (context, inject) => {
  inject('tracking', trackingPlugin(context));
};
