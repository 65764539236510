import { DEFAULT_LANGUAGE } from '~/config/languages';

export default ({ app, store, route, query, req }, inject) => {
  const baseURL = `${store.$env['BASE_URL_PROTOCOL']}://${
    store.$env['BASE_URL_' + store.state.session.language.lang.toUpperCase()] ||
    store.$env['BASE_URL_' + store.state.session.language.tag.toUpperCase()]
  }${app.context.isDev ? ':3333' : ''}`;

  const actualLangIsoUrl = store.state.session.language.url.toLowerCase();
  const actualEndingTrailingLanguage = actualLangIsoUrl !== 'en' ? actualLangIsoUrl + '/' : '';
  const staticWebUrl = `${store.$env['STATICWEB_URL']}${actualEndingTrailingLanguage}`;

  const isDevMode = route?.query?.dev === '1';
  const spwaSuffix = store.$env['STATIC_URL'] || '/';

  // @TODO: After migration
  const {
    web_redirectGeneric,
    web_redirectWorldwide,
    web_redirectContinent,
    web_redirectCountry,
    web_redirectArea,
    web_redirectRegion,
    web_redirectCity,
    web_redirectDistrict,
    web_redirectProperty,
  } = store.$optimizely.isFeatureListEnabled([
    'web_redirectGeneric',
    'web_redirectWorldwide',
    'web_redirectContinent',
    'web_redirectCountry',
    'web_redirectArea',
    'web_redirectRegion',
    'web_redirectCity',
    'web_redirectDistrict',
    'web_redirectProperty',
  ]);

  const buildExtraParams = (params, addQuestionMark = 0) => {
    let qs = isDevMode ? '?dev=1' : '';

    if (params && Object.keys(params).length > 0) {
      let queryParams = new URLSearchParams(params);
      qs = qs.startsWith('?') ? qs + '&' + queryParams.toString() : qs + '?' + queryParams.toString();
    }
    return qs;
  };

  const buildPageNumbers = (pageNumber) => {
    let pageNumbers = '';

    if (pageNumber && pageNumber > 0) {
      pageNumbers = `p/${pageNumber}/`;
    }

    return pageNumbers;
  };

  const getHomepageUrl = () => {
    return staticWebUrl;
  };

  const buildWorldPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const worldwidePath = `${locationData.propertyTypeSlug}/`;
    if (web_redirectWorldwide) {
      return `${staticWebUrl}${worldwidePath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${worldwidePath}${qs}`;
  };

  const replacePropertyTypeFromUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const currentPath = route.path
      .split('/')
      .filter((param) => param)
      .slice(1);
    const newPath = [locationData.propertyTypeSlug, ...currentPath].join('/');
    const startUrl = `${baseURL}${spwaSuffix}`;

    return `${startUrl}${newPath}/${qs}`;
  };

  const buildContinentPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const continentPath = `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/`;
    if (web_redirectContinent) {
      return `${staticWebUrl}${continentPath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${continentPath}${qs}`;
  };

  const buildCountryPageUrl = (locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const countryPath = `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/`;
    if (web_redirectCountry) {
      return `${staticWebUrl}${countryPath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${countryPath}${qs}`;
  };

  const buildLanguageSubdomain = (lang, withProtocol = false) => {
    const baseUrlLangCode = {
      en_GB: 'EN',
      pt_BR: 'BR',
      zh_CN: 'ZH',
      cs_CZ: 'CS',
      da_DK: 'DA',
      nl_NL: 'NL',
      fi_FI: 'FI',
      fr_FR: 'FR',
      de_DE: 'DE',
      it_IT: 'IT',
      ja_JP: 'JA',
      ko_KR: 'KO',
      nb_NO: 'NB',
      nn_NO: 'NB',
      pl_PL: 'PL',
      pt_PT: 'PT',
      ru_RU: 'RU',
      es_ES: 'ES',
      sv_SE: 'SV',
      tr_TR: 'TR',
    };
    let baseSubdomain =
      store.$env['BASE_URL_' + baseUrlLangCode[lang]] || store.$env['BASE_URL_' + DEFAULT_LANGUAGE.tag.toUpperCase()];
    baseSubdomain += app.context.isDev ? ':3333' : '';
    return withProtocol ? `${store.$env.BASE_URL_PROTOCOL}://${baseSubdomain}` : baseSubdomain;
  };

  const buildHrefLangUrl = (locationData) => {
    const langBaseUrl = buildLanguageSubdomain(locationData?.hrefLang);
    return `${store.$env['BASE_URL_PROTOCOL']}://${langBaseUrl}${spwaSuffix}`;
  };

  const buildHrefLangUrlforWorldwide = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/`;
  };

  const buildHrefLangUrlforGenericPage = (locationData, urlStructure) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${urlStructure}/`;
  };

  const buildHrefLangUrlforContinent = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/`;
  };

  const buildHrefLangUrlforCountry = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/${locationData.country}/`;
  };

  const buildHrefLangUrlforArea = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/${locationData.country}/a/${locationData.area}/`;
  };

  const buildHrefLangUrlforRegion = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/${locationData.country}/r/${locationData.region}/`;
  };

  const buildHrefLangUrlforCity = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/${locationData.country}/${locationData.city}/`;
  };

  const buildHrefLangUrlforDistrict = (locationData, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/${locationData.continent}/${locationData.country}/${locationData.city}/d/${locationData.district}/`;
  };

  const buildHrefLangUrlforProperty = (locationData, propertyId, propertyType) => {
    const mainBaseUrlByLanguage = buildHrefLangUrl(locationData);
    return `${mainBaseUrlByLanguage}${propertyType}/p/${propertyId}/${locationData.property}/`;
  };

  const buildContinentPageUrlByLang = (propertyType, locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const langBaseUrl = buildLanguageSubdomain(locationData?.hrefLang);
    return `${store.$env['BASE_URL_PROTOCOL']}://${langBaseUrl}${spwaSuffix}${propertyType}/${locationData.continent}/${qs}`;
  };

  const buildCityPageUrlByLang = (propertyType, locationData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const langBaseUrl = buildLanguageSubdomain(locationData?.hrefLang);
    return `${store.$env['BASE_URL_PROTOCOL']}://${langBaseUrl}${spwaSuffix}${propertyType}/${locationData.continent}/${locationData.country}/${locationData.city}/${qs}`;
  };

  const buildCityUrl = (locationData, extraParams, pageNr) => {
    const pageNumbers = buildPageNumbers(pageNr);
    const qs = buildExtraParams(extraParams, 1);
    const cityPath =
      `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/${locationData.urlFriendlyCity}/${pageNumbers}`.replace(
        /\/+$/,
        ''
      );

    if (web_redirectCity) {
      return `${staticWebUrl}${cityPath}/${qs}`;
    }

    return `${baseURL}${spwaSuffix}${cityPath}/${qs}`;
  };

  const buildCityFilterUrl = (locationData, extraParams, pageNr) => {
    const pageNumbers = buildPageNumbers(pageNr);
    const qs = buildExtraParams(extraParams, 1);
    const filterPath =
      `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/${locationData.urlFriendlyCity}/f/${locationData.urlFilterSlug}/${pageNumbers}`.replace(
        /\/+$/,
        ''
      );

    if (web_redirectCity) {
      return `${staticWebUrl}${filterPath}/${qs}`;
    }

    return `${baseURL}${spwaSuffix}${filterPath}/${qs}`;
  };

  const buildAreasUrl = (locationData, areasData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const areaPath = `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/a/${areasData.urlFriendlyName}/`;

    if (web_redirectArea) {
      return `${staticWebUrl}${areaPath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${areaPath}${qs}`;
  };

  const buildRegionsUrl = (locationData, regionsData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const regionPath = `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/r/${regionsData.urlFriendlyName}/`;

    if (web_redirectRegion) {
      return `${staticWebUrl}${regionPath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${regionPath}${qs}`;
  };

  const buildDistrictsUrl = (locationData, districtsData, extraParams, pageNr) => {
    const pageNumbers = buildPageNumbers(pageNr);
    const qs = buildExtraParams(extraParams, 1);
    const districtPath =
      `${locationData.propertyTypeSlug}/${locationData.urlFriendlyContinent}/${locationData.urlFriendlyCountry}/${locationData.urlFriendlyCity}/d/${districtsData.urlFriendlyName}/${pageNumbers}`.replace(
        /\/+$/,
        ''
      );

    if (web_redirectDistrict) {
      return `${staticWebUrl}${districtPath}/${qs}`;
    }

    return `${baseURL}${spwaSuffix}${districtPath}/${qs}`;
  };

  const buildPropertyUrl = (locationData, propertyData, extraParams, isDynamic = false) => {
    const propertyPath = `${locationData.propertyTypeSlug}/p/${propertyData.id}/${propertyData.urlFriendlyName}/`;
    const qs = buildExtraParams(extraParams, 1);

    if (web_redirectProperty) {
      return `${staticWebUrl}${propertyPath}${qs}`;
    }

    return `${baseURL}${spwaSuffix}${propertyPath}${qs}`;
  };

  const buildPwaPropertyUrl = (locationData, propertyData, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);
    const basePath = 'pwa/hosteldetails.php';
    return `${baseURL}/${basePath}/${propertyData.urlFriendlyName}/${locationData.urlFriendlyName}/${propertyData.id}/${qs}`;
  };

  const buildGenericPageUrl = (urlStructure, extraParams) => {
    const qs = buildExtraParams(extraParams, 1);

    if (web_redirectGeneric) {
      return `${staticWebUrl}${urlStructure}/${qs}`;
    }
    return `${baseURL}${spwaSuffix}${urlStructure}/${qs}`;
  };

  const buildCanonicalGenericPageUrl = (urlStructure) => {
    return `${baseURL}${spwaSuffix}${urlStructure}/`;
  };

  const buildCityDynamicFabUrl = (locationData, extraParams) => {
    const humanReadableSearchQuery =
      locationData?.cityName && locationData?.countryName
        ? `${locationData.cityName}, ${locationData.countryName}`
        : `${locationData.urlFriendlyName}, ${locationData.urlFriendlyCountry}`;

    const params = {
      q: humanReadableSearchQuery,
      country: `${locationData.urlFriendlyCountry}`,
      city: `${locationData.urlFriendlyName}`,
      type: `city`,
      id: `${locationData.id}`,
    };
    extraParams = { ...params, ...extraParams };
    let qs = buildExtraParams(extraParams);

    return `${baseURL}/pwa/wds/s${qs}`;
  };

  inject('url', {
    getBaseURL: () => baseURL,
    getHomepageUrl,
    getCityDynamicFabUrl: (locationData, extraParams) => buildCityDynamicFabUrl(locationData, extraParams),
    getWorldPageUrl: (locationData, extraParams) => buildWorldPageUrl(locationData, extraParams),
    getReplacedPropertyTypeUrl: (locationData, extraParams) => replacePropertyTypeFromUrl(locationData, extraParams),
    getContinentPageUrl: (locationData, extraParams) => buildContinentPageUrl(locationData, extraParams),
    getCountryPageUrl: (locationData, extraParams) => buildCountryPageUrl(locationData, extraParams),
    getCityUrl: (locationData, extraParams, pageNr) => buildCityUrl(locationData, extraParams, pageNr),
    getCityFilterUrl: (locationData, extraParams, pageNr) => buildCityFilterUrl(locationData, extraParams, pageNr),
    getCityPageUrlByLang: (propertyType, locationData, extraParams) =>
      buildCityPageUrlByLang(propertyType, locationData, extraParams),
    getContinentPageUrlByLang: (propertyType, locationData, extraParams) =>
      buildContinentPageUrlByLang(propertyType, locationData, extraParams),
    getLanguageSubdomain: (lang, withProtocol) => buildLanguageSubdomain(lang, withProtocol),
    getAreasUrl: (locationData, areasData, extraParams) => buildAreasUrl(locationData, areasData, extraParams),
    getRegionsUrl: (locationData, regionsData, extraParams) => buildRegionsUrl(locationData, regionsData, extraParams),
    getDistrictsUrl: (locationData, districtsData, extraParams, pageNr) =>
      buildDistrictsUrl(locationData, districtsData, extraParams, pageNr),
    getPropertyUrl: (locationData, propertyData, extraParams) =>
      buildPropertyUrl(locationData, propertyData, extraParams),
    getPropertyDynamicUrl: (locationData, propertyData, extraParams) =>
      buildPropertyUrl(locationData, propertyData, extraParams, true),
    getPwaPropertyDynamicUrl: (locationData, propertyData, extraParams) =>
      buildPwaPropertyUrl(locationData, propertyData, extraParams),
    getGenericPageUrl: (urlStructure, extraParams) => buildGenericPageUrl(urlStructure, extraParams),
    getCanonicalGenericPageUrl: (urlStructure) => buildCanonicalGenericPageUrl(urlStructure),
    getHrefLangUrl: (locationData) => buildHrefLangUrl(locationData),
    getHrefLangUrlforWorldwide: (locationData, propertyType) =>
      buildHrefLangUrlforWorldwide(locationData, propertyType),
    getHrefLangUrlforContinent: (locationData, propertyType) =>
      buildHrefLangUrlforContinent(locationData, propertyType),
    getHrefLangUrlforCountry: (locationData, propertyType) => buildHrefLangUrlforCountry(locationData, propertyType),
    getHrefLangUrlforArea: (locationData, propertyType) => buildHrefLangUrlforArea(locationData, propertyType),
    getHrefLangUrlforRegion: (locationData, propertyType) => buildHrefLangUrlforRegion(locationData, propertyType),
    getHrefLangUrlforCity: (locationData, propertyType) => buildHrefLangUrlforCity(locationData, propertyType),
    getHrefLangUrlforDistrict: (locationData, propertyType) => buildHrefLangUrlforDistrict(locationData, propertyType),
    getHrefLangUrlforProperty: (locationData, propertyId, propertyType) =>
      buildHrefLangUrlforProperty(locationData, propertyId, propertyType),
    getHrefLangUrlforGenericPage: (locationData, urlStructure) =>
      buildHrefLangUrlforGenericPage(locationData, urlStructure),
  });
};
