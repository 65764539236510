export default ({ app, store, route, query, redirect }) => {
  if (!process.server) {
    return;
  }

  const isDevMode = query?.dev === '1';

  if (isDevMode) {
    return;
  }

  const genericPages = {
    backpackers: 'backpackers',
    bestpriceguarantee: 'bestpriceguarantee',
    cookiespolicy: 'cookiespolicy',
    groupsdataprotectionnotice: 'groupsdataprotectionnotice',
    guarantee: 'guarantee',
    'guarantee-terms': 'guarantee/terms',
    hosteltermsandconditions: 'hosteltermsandconditions',
    'mobile-app': 'mobile-app',
    'mobile-app-download': 'mobile-app',
    reviewguidelines: 'reviewguidelines',
    securityprivacy: 'securityprivacy',
    sitemap: 'sitemap',
    'website-testing': 'website-testing',
  };

  const {
    web_redirectWorldwide,
    web_redirectContinent,
    web_redirectCountry,
    web_redirectArea,
    web_redirectRegion,
    web_redirectCity,
    web_redirectDistrict,
    web_redirectProperty,
    web_redirectGeneric,
  } = store.$optimizely.isFeatureListEnabled([
    'web_redirectWorldwide',
    'web_redirectContinent',
    'web_redirectCountry',
    'web_redirectArea',
    'web_redirectRegion',
    'web_redirectCity',
    'web_redirectDistrict',
    'web_redirectProperty',
    'web_redirectGeneric',
  ]);

  let routes = ['index'];

  if (web_redirectWorldwide) {
    routes.push('propertyType');
  }

  if (web_redirectContinent) {
    routes.push('propertyType-continent');
  }

  if (web_redirectCountry) {
    routes.push('propertyType-continent-country');
  }

  if (web_redirectArea) {
    routes.push('propertyType-continent-country-a-area');
  }

  if (web_redirectRegion) {
    routes.push('propertyType-continent-country-r-region');
  }

  if (web_redirectCity) {
    routes.push('propertyType-continent-country-city-all');
  }

  if (web_redirectDistrict) {
    routes.push('propertyType-continent-country-city-d-district-all');
  }

  if (web_redirectProperty) {
    routes.push('propertyType-p-propertyId-propertyName');
  }

  if (web_redirectGeneric) {
    routes = [...routes, ...Object.keys(genericPages)];
  }

  const shouldRouteBeRedirect = routes.includes(route.name);

  let redirectUrl = null;

  if (shouldRouteBeRedirect) {
    if (Object.keys(genericPages).includes(route.name)) {
      redirectUrl = app.$url.getGenericPageUrl(genericPages[route.name], query);
    } else {
      switch (route.name) {
        case 'index': // Homepage (index)
          redirectUrl = app.$url.getBaseURL();
          break;
        case 'propertyType': // Worldwide Page
          redirectUrl = app.$url.getWorldPageUrl({ propertyTypeSlug: route.params.propertyType }, query);
          break;
        case 'propertyType-continent': // Continent Page
          redirectUrl = app.$url.getContinentPageUrl(
            {
              propertyTypeSlug: route.params.propertyType,
              urlFriendlyContinent: route.params.continent,
            },
            query
          );
          break;
        case 'propertyType-continent-country': // Country Page
          redirectUrl = app.$url.getCountryPageUrl(
            {
              propertyTypeSlug: route.params.propertyType,
              urlFriendlyContinent: route.params.continent,
              urlFriendlyCountry: route.params.country,
            },
            query
          );
          break;
        case 'propertyType-continent-country-a-area': // Area Page
          redirectUrl = app.$url.getAreasUrl(
            {
              propertyTypeSlug: route.params.propertyType,
              urlFriendlyContinent: route.params.continent,
              urlFriendlyCountry: route.params.country,
            },
            {
              urlFriendlyName: route.params.area,
            },
            query
          );
          break;
        case 'propertyType-continent-country-r-region': // Regions Page
          redirectUrl = app.$url.getRegionsUrl(
            {
              propertyTypeSlug: route.params.propertyType,
              urlFriendlyContinent: route.params.continent,
              urlFriendlyCountry: route.params.country,
            },
            {
              urlFriendlyName: route.params.region,
            },
            query
          );
          break;
        case 'propertyType-continent-country-city-all': // City Page
          const cityFilter = route.params?.pathMatch.match(/^f\/([^\s]+)$/);
          const cityPage = route.params?.pathMatch.match(/^p\/(\d+)/);
          const cityFilterSlug = cityFilter?.length ? cityFilter[1] : null;
          const cityPageNumber = cityPage?.length ? cityPage[1] : null;

          const cityPathData = {
            propertyTypeSlug: route.params.propertyType,
            urlFriendlyContinent: route.params.continent,
            urlFriendlyCountry: route.params.country,
            urlFriendlyCity: route.params.city,
            urlFilterSlug: cityFilterSlug,
          };

          redirectUrl = cityFilter?.length
            ? app.$url.getCityFilterUrl(cityPathData, query, cityPageNumber)
            : app.$url.getCityUrl(cityPathData, query, cityPageNumber);
          break;
        case 'propertyType-continent-country-city-d-district-all': // District Page
          const districtPage = route.params.pathMatch.match(/^p\/(\d+)/);
          const districtPageNumber = districtPage?.length ? districtPage[1] : null;

          const districtData = {
            propertyTypeSlug: route.params.propertyType,
            urlFriendlyContinent: route.params.continent,
            urlFriendlyCountry: route.params.country,
            urlFriendlyCity: route.params.city,
          };

          redirectUrl = app.$url.getDistrictsUrl(
            districtData,
            {
              urlFriendlyName: route.params.district,
            },
            query,
            districtPageNumber
          );
          break;
        case 'propertyType-p-propertyId-propertyName': // Property Page
          redirectUrl = app.$url.getPropertyUrl(
            {
              propertyTypeSlug: route.params.propertyType,
            },
            {
              id: route.params.propertyId,
              urlFriendlyName: route.params.propertyName,
            },
            query
          );
          break;
      }
    }

    if (redirectUrl) {
      return redirect(301, redirectUrl);
    }
  }
};
