const CURRENCIES = [
  { code: 'EUR', transcode: 't_CURRENCYEUR', symbol: '€' },
  { code: 'USD', transcode: 't_CURRENCYUSD', symbol: 'US$' },
  { code: 'GBP', transcode: 't_CURRENCYGBP', symbol: '£' },
  { code: 'ALL', transcode: 't_CURRENCYALL', symbol: 'ALL' },
  { code: 'DZD', transcode: 't_CURRENCYDZD', symbol: 'DA' },
  { code: 'ARS', transcode: 't_CURRENCYARS', symbol: 'AR$' },
  { code: 'AMD', transcode: 't_CURRENCYAMD', symbol: 'AMD' },
  { code: 'AUD', transcode: 't_CURRENCYAUD', symbol: 'AU$' },
  { code: 'AZN', transcode: 't_CURRENCYAZN', symbol: 'man.' },
  { code: 'BSD', transcode: 't_CURRENCYBSD', symbol: 'BS$' },
  { code: 'BHD', transcode: 't_CURRENCYBHD', symbol: 'BD' },
  { code: 'BDT', transcode: 't_CURRENCYBDT', symbol: 'Tk' },
  { code: 'BZD', transcode: 't_CURRENCYBZD', symbol: 'BZ$' },
  { code: 'BOB', transcode: 't_CURRENCYBOB', symbol: 'Bs' },
  { code: 'BRL', transcode: 't_CURRENCYBRL', symbol: 'R$' },
  { code: 'BND', transcode: 't_CURRENCYBND', symbol: 'BN$' },
  { code: 'BGN', transcode: 't_CURRENCYBGN', symbol: 'BGN' },
  { code: 'KHR', transcode: 't_CURRENCYKHR', symbol: 'KHR' },
  { code: 'CAD', transcode: 't_CURRENCYCAD', symbol: 'CA$' },
  { code: 'XOF', transcode: 't_CURRENCYXOF', symbol: 'CFA' },
  { code: 'XAF', transcode: 't_CURRENCYXAF', symbol: 'FCFA' },
  { code: 'XPF', transcode: 't_CURRENCYXPF', symbol: 'CFPF' },
  { code: 'CLP', transcode: 't_CURRENCYCLP', symbol: 'CL$' },
  { code: 'CNY', transcode: 't_CURRENCYCNY', symbol: 'CN¥' },
  { code: 'COP', transcode: 't_CURRENCYCOP', symbol: 'CO$' },
  { code: 'KMF', transcode: 't_CURRENCYKMF', symbol: 'CF' },
  { code: 'CRC', transcode: 't_CURRENCYCRC', symbol: 'CRC' },
  { code: 'HRK', transcode: 't_CURRENCYHRK', symbol: 'kn' },
  { code: 'CUC', transcode: 't_CURRENCYCUC', symbol: 'CUC$' },
  { code: 'CZK', transcode: 't_CURRENCYCZK', symbol: 'Kč' },
  { code: 'DKK', transcode: 't_CURRENCYDKK', symbol: 'Dkr' },
  { code: 'DJF', transcode: 't_CURRENCYDJF', symbol: 'Fdj' },
  { code: 'DOP', transcode: 't_CURRENCYDOP', symbol: 'RD$' },
  { code: 'XCD', transcode: 't_CURRENCYXCD', symbol: 'EC$' },
  { code: 'EGP', transcode: 't_CURRENCYEGP', symbol: 'EG£' },
  { code: 'FJD', transcode: 't_CURRENCYFJD', symbol: 'FJ$' },
  { code: 'GEL', transcode: 't_CURRENCYGEL', symbol: 'GEL' },
  { code: 'GTQ', transcode: 't_CURRENCYGTQ', symbol: 'GTQ' },
  { code: 'HNL', transcode: 't_CURRENCYHNL', symbol: 'HNL' },
  { code: 'HKD', transcode: 't_CURRENCYHKD', symbol: 'HK$' },
  { code: 'HUF', transcode: 't_CURRENCYHUF', symbol: 'Ft' },
  { code: 'ISK', transcode: 't_CURRENCYISK', symbol: 'Ikr' },
  { code: 'INR', transcode: 't_CURRENCYINR', symbol: 'Rs' },
  { code: 'IDR', transcode: 't_CURRENCYIDR', symbol: 'Rp' },
  { code: 'IRR', transcode: 't_CURRENCYIRR', symbol: 'IRR' },
  { code: 'ILS', transcode: 't_CURRENCYILS', symbol: '₪' },
  { code: 'JPY', transcode: 't_CURRENCYJPY', symbol: '¥' },
  { code: 'JOD', transcode: 't_CURRENCYJOD', symbol: 'JD' },
  { code: 'KZT', transcode: 't_CURRENCYKZT', symbol: 'KZT' },
  { code: 'KES', transcode: 't_CURRENCYKES', symbol: 'Ksh' },
  { code: 'KWD', transcode: 't_CURRENCYKWD', symbol: 'KD' },
  { code: 'KGS', transcode: 't_CURRENCYKGS', symbol: 'KGS' },
  { code: 'LAK', transcode: 't_CURRENCYLAK', symbol: 'LAK' },
  { code: 'LBP', transcode: 't_CURRENCYLBP', symbol: 'LB£' },
  { code: 'MYR', transcode: 't_CURRENCYMYR', symbol: 'RM' },
  { code: 'MRO', transcode: 't_CURRENCYMRO', symbol: 'UM' },
  { code: 'MUR', transcode: 't_CURRENCYMUR', symbol: 'MURs' },
  { code: 'MXN', transcode: 't_CURRENCYMXN', symbol: 'MXN' },
  { code: 'MNT', transcode: 't_CURRENCYMNT', symbol: 'MNT' },
  { code: 'MAD', transcode: 't_CURRENCYMAD', symbol: 'MAD' },
  { code: 'NAD', transcode: 't_CURRENCYNAD', symbol: 'N$' },
  { code: 'NPR', transcode: 't_CURRENCYNPR', symbol: 'NPR' },
  { code: 'NZD', transcode: 't_CURRENCYNZD', symbol: 'NZ$' },
  { code: 'NIO', transcode: 't_CURRENCYNIO', symbol: 'C$' },
  { code: 'NOK', transcode: 't_CURRENCYNOK', symbol: 'Nkr' },
  { code: 'OMR', transcode: 't_CURRENCYOMR', symbol: 'OMR' },
  { code: 'PKR', transcode: 't_CURRENCYPKR', symbol: 'PKRs' },
  { code: 'PGK', transcode: 't_CURRENCYPGK', symbol: 'PGK' },
  { code: 'PYG', transcode: 't_CURRENCYPYG', symbol: 'PYG' },
  { code: 'PEN', transcode: 't_CURRENCYPEN', symbol: 'S/.' },
  { code: 'PHP', transcode: 't_CURRENCYPHP', symbol: 'PHP' },
  { code: 'PLN', transcode: 't_CURRENCYPLN', symbol: 'zł' },
  { code: 'QAR', transcode: 't_CURRENCYQAR', symbol: 'QAR' },
  { code: 'RON', transcode: 't_CURRENCYRON', symbol: 'RON' },
  { code: 'RUB', transcode: 't_CURRENCYRUB', symbol: 'RUB' },
  { code: 'RWF', transcode: 't_CURRENCYRWF', symbol: 'RWF' },
  { code: 'WST', transcode: 't_CURRENCYWST', symbol: 'WST' },
  { code: 'SAR', transcode: 't_CURRENCYSAR', symbol: 'SR' },
  { code: 'SGD', transcode: 't_CURRENCYSGD', symbol: 'S$' },
  { code: 'SOS', transcode: 't_CURRENCYSOS', symbol: 'Ssh' },
  { code: 'ZAR', transcode: 't_CURRENCYZAR', symbol: 'R' },
  { code: 'KRW', transcode: 't_CURRENCYKRW', symbol: '₩' },
  { code: 'LKR', transcode: 't_CURRENCYLKR', symbol: 'SLRs' },
  { code: 'SZL', transcode: 't_CURRENCYSZL', symbol: 'SZL' },
  { code: 'SEK', transcode: 't_CURRENCYSEK', symbol: 'Skr' },
  { code: 'CHF', transcode: 't_CURRENCYCHF', symbol: 'Fr.' },
  { code: 'TWD', transcode: 't_CURRENCYTWD', symbol: 'NT$' },
  { code: 'TZS', transcode: 't_CURRENCYTZS', symbol: 'TSh' },
  { code: 'THB', transcode: 't_CURRENCYTHB', symbol: 'THB' },
  { code: 'TOP', transcode: 't_CURRENCYTOP', symbol: 'T$' },
  { code: 'TTD', transcode: 't_CURRENCYTTD', symbol: 'TT$' },
  { code: 'TND', transcode: 't_CURRENCYTND', symbol: 'DT' },
  { code: 'TRY', transcode: 't_CURRENCYTRY', symbol: 'TL' },
  { code: 'UGX', transcode: 't_CURRENCYUGX', symbol: 'USh' },
  { code: 'UAH', transcode: 't_CURRENCYUAH', symbol: 'UAH' },
  { code: 'UYU', transcode: 't_CURRENCYUYU', symbol: '$U' },
  { code: 'AED', transcode: 't_CURRENCYAED', symbol: 'AED' },
  { code: 'VUV', transcode: 't_CURRENCYVUV', symbol: 'VT' },
  { code: 'VEF', transcode: 't_CURRENCYVEF', symbol: 'Bs.F.' },
  { code: 'VND', transcode: 't_CURRENCYVND', symbol: '₫' },
];

const DEFAULT_CURRENCY = CURRENCIES[0];

module.exports = {
  CURRENCIES,
  DEFAULT_CURRENCY,
};
