const MAX_NUM_NIGHTS = 30;

export const state = /* istanbul ignore next */ () => ({
  searchParams: {
    location: {},
    checkIn: {},
    checkOut: {},
    disabledUntil: {},
    disabledFrom: {},
    guests: {},
    groupType: {},
    groupAge: [],
    phrase: '',
  },
  isSticky: false,
  isSearchActivated: false,
});

export const mutations = {
  SET_STICKY(state, value) {
    state.isSticky = value;
  },
  SET_SEARCH_ACTIVATED(state, value) {
    state.isSearchActivated = value;
  },
  setSearchLocation(state, value) {
    state.searchParams.location = value;
  },
  setSearchCheckIn(state, value) {
    state.searchParams.checkIn = value;

    const start = new Date(value.year, value.month - 1, value.day);
    const disabledUntil = new Date(new Date(start).setDate(start.getDate() + 1));
    const disableFrom = new Date(start.getFullYear(), start.getMonth(), start.getDate() + MAX_NUM_NIGHTS);

    state.searchParams.disabledUntil = {
      year: disabledUntil.getFullYear(),
      month: disabledUntil.getMonth() + 1,
      day: disabledUntil.getDate(),
    };

    state.searchParams.disabledFrom = {
      year: disableFrom.getFullYear(),
      month: disableFrom.getMonth() + 1,
      day: disableFrom.getDate(),
    };
  },
  setSearchCheckOut(state, value) {
    state.searchParams.checkOut = value;
  },
  setSearchNumberOfGuests(state, value) {
    state.searchParams.guests = value;
  },
  setSearchGroupType(state, value) {
    state.searchParams.groupType = value;
  },
  setSearchGroupAge(state, value) {
    state.searchParams.groupAge = value;
  },
  addSearchGroupAge(state, value) {
    state.searchParams.groupAge.push(value);
  },
  setSearchPhrase(state, value) {
    state.searchParams.phrase = value;
  },
};

export const actions = {
  sendSearchActivatedTracking({ commit, getters }, { tracking, content }) {
    // STATICPWA-387 - Only one searchActivated event per pageview
    if (getters.getIsSearchActivated) {
      return;
    }

    try {
      const defaultStickValues = { parameter1: 'Dates', parameter2: 'Sticky Bar' };
      const value = getters.getIsSticky ? defaultStickValues : content;
      tracking.onSearchActivated(value);
      commit('SET_SEARCH_ACTIVATED', true);
    } catch (e) {
      console.error(`Unable to set searchActivated event: ${e}`);
    }
  },
  setIsSticky({ commit }, value) {
    commit('SET_STICKY', value);
  },
  setSearchLocation({ commit }, value) {
    commit('setSearchLocation', value);
  },
  setSearchCheckIn({ commit }, value) {
    commit('setSearchCheckIn', value);
  },
  setSearchCheckOut({ commit }, value) {
    commit('setSearchCheckOut', value);
  },
  setSearchNumberOfGuests({ commit }, value) {
    commit('setSearchNumberOfGuests', value);
  },
  setSearchGroupType({ commit }, value) {
    commit('setSearchGroupType', value);
  },
  setSearchGroupAge({ commit }, value) {
    commit('setSearchGroupAge', value);
  },
  addSearchGroupAge({ commit }, value) {
    commit('addSearchGroupAge', value);
  },
  setSearchActivated({ commit }, value) {
    commit('SET_SEARCH_ACTIVATED', value);
  },
  setSearchPhrase({ commit }, value) {
    commit('setSearchPhrase', value);
  },
};

export const getters = {
  getSearchLocation(state) {
    return state.searchParams.location;
  },
  getSearchCheckIn(state) {
    return state.searchParams.checkIn;
  },
  getSearchDisabledUntil(state) {
    return state.searchParams.disabledUntil;
  },
  getSearchDisableFrom(state) {
    return state.searchParams.disabledFrom;
  },
  getSearchCheckOut(state) {
    return state.searchParams.checkOut;
  },
  getSearchNumberOfGuests(state) {
    return state.searchParams.guests;
  },
  getSearchGroupType(state) {
    return state.searchParams.groupType;
  },
  getSearchGroupAge(state) {
    return state.searchParams.groupAge;
  },
  getIsSticky(state) {
    return state.isSticky;
  },
  getIsSearchActivated(state) {
    return state.isSearchActivated;
  },
  getSearchPhrase(state) {
    return state.searchParams.phrase;
  },
};
