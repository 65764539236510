import { parse } from '~/lib/cookie';

export default class Gdpr {
  #GDPR_ALLOW_ALL = ['permit 1,2,3'];
  #GDPR_ALLOW_FUNCTIONAL_ONLY = ['permit 1,2 functional'];

  constructor() {
    this.isTrackingPermitted = false;
    const cookieContent = document.cookie ? parse(document.cookie) : {};
    const cmapiPrivacyCookie = cookieContent['cmapi_cookie_privacy'] || '';
    const noticeBehaviorCookie = cookieContent['notice_behavior'] || '';
    const behaviour = noticeBehaviorCookie === 'implied,eu';

    const cmapiPrivacyCookieAllowed = [...this.#GDPR_ALLOW_ALL, ...this.#GDPR_ALLOW_FUNCTIONAL_ONLY].includes(
      cmapiPrivacyCookie
    );

    this.isTrackingPermitted = !behaviour || (behaviour && cmapiPrivacyCookieAllowed);
  }

  isTrackingAllowed() {
    return this.isTrackingPermitted;
  }
}
