import { LANGUAGES, DEFAULT_LANGUAGE } from '~/config/languages';
import { PROPERTY_TYPES } from '~/config/property-types';
import LinkTag from '~/models/LinkTag';
import t_SLUGPROPERTYTYPEHOSTELS from '~/locales/all/t_SLUGPROPERTYTYPEHOSTELS';
import t_SLUGPROPERTYTYPEHOTELS from '~/locales/all/t_SLUGPROPERTYTYPEHOTELS';
import t_SLUGPROPERTYTYPEBNBS from '~/locales/all/t_SLUGPROPERTYTYPEBNB';

export default ({ app }, inject) => {
  const propertyTypesTranscodes = {
    t_PROPERTYTYPEHOSTELS: t_SLUGPROPERTYTYPEHOSTELS,
    t_PROPERTYTYPEHOTELS: t_SLUGPROPERTYTYPEHOTELS,
    t_PROPERTYTYPEBNBS: t_SLUGPROPERTYTYPEBNBS,
  };
  const getLanguageByCode = (lang) => LANGUAGES.find((language) => language.code === lang) || DEFAULT_LANGUAGE;
  const getPropertyTypeByKey = (key) => PROPERTY_TYPES.find((properties) => properties.key === key.toLowerCase());
  const getLocationByLang = (locations, lang) => locations.find((location) => location.hrefLang === lang);
  const getPropertyTypeForHref = (propertyType, lang, propertyTypeSlug) =>
    propertyTypesTranscodes[propertyType?.transcode]?.[lang.code] || propertyTypeSlug;
  const getAlternateData = (rel, href, lang) => {
    return { rel: rel, href: href, hreflang: lang };
  };

  const buildLinkTag = () => {
    return new LinkTag();
  };

  const buildCanonicalTag = (linkTag, href) => {
    linkTag.addLinks([{ rel: 'canonical', href: href }]);
    return linkTag;
  };

  const buildAlternateTagWithPropertyType = (
    linkTag,
    urlFunc,
    locationTranslations,
    propertyTypeKey,
    propertyTypeSlug
  ) => {
    if (locationTranslations && locationTranslations?.length) {
      const linksToAdd = locationTranslations.map((l) => {
        const langData = getLanguageByCode(l.hrefLang);
        const propertyType = getPropertyTypeByKey(propertyTypeKey);
        const propertyTypeForHref = getPropertyTypeForHref(propertyType, langData, propertyTypeSlug);
        return getAlternateData('alternate', urlFunc(l, propertyTypeForHref.toLowerCase()), langData.tag);
      });
      linkTag.addLinks(linksToAdd);
    }
    return linkTag;
  };

  const buildAlternateTagForProperty = (
    linkTag,
    urlFunc,
    locationTranslations,
    propertyTypeKey,
    propertyTypeSlug,
    propertyID
  ) => {
    if (locationTranslations && locationTranslations?.length) {
      const linksToAdd = locationTranslations.map((l) => {
        const langData = getLanguageByCode(l.hrefLang);
        const propertyType = getPropertyTypeByKey(propertyTypeKey);
        const propertyTypeForHref = getPropertyTypeForHref(propertyType, langData, propertyTypeSlug);
        return getAlternateData('alternate', urlFunc(l, propertyID, propertyTypeForHref.toLowerCase()), langData.tag);
      });
      linkTag.addLinks(linksToAdd);
    }
    return linkTag;
  };

  const buildGenericPageAlternateTag = (linkTag, urlFunc, locationTranslations, pageUrlStructure) => {
    if (locationTranslations && locationTranslations?.length) {
      const linksToAdd = locationTranslations.map((l) => {
        const langData = getLanguageByCode(l.hrefLang);
        return getAlternateData('alternate', urlFunc(l, pageUrlStructure), langData.tag);
      });
      linkTag.addLinks(linksToAdd);
    }
    return linkTag;
  };

  const buildDefaultAlternateTagWithPropertyType = (
    linkTag,
    urlFunc,
    locationTranslations,
    propertyTypeKey,
    propertyTypeSlug
  ) => {
    if (locationTranslations && locationTranslations?.length) {
      const location = getLocationByLang(locationTranslations, DEFAULT_LANGUAGE.code);
      const propertyType = getPropertyTypeByKey(propertyTypeKey);
      const propertyTypeForHref = getPropertyTypeForHref(propertyType, DEFAULT_LANGUAGE, propertyTypeSlug);
      linkTag.addLinks([
        getAlternateData('alternate', urlFunc(location, propertyTypeForHref.toLowerCase()), 'x-default'),
      ]);
    }
    return linkTag;
  };

  const buildGenericPageDefaultAlternateTag = (linkTag, urlFunc, pageUrlStructure) => {
    linkTag.addLinks([getAlternateData('alternate', urlFunc(DEFAULT_LANGUAGE, pageUrlStructure), 'x-default')]);
    return linkTag;
  };

  const buildDefaultAlternateTagForProperty = (
    linkTag,
    urlFunc,
    locationTranslations,
    propertyTypeKey,
    propertyTypeSlug,
    propertyID
  ) => {
    if (locationTranslations && locationTranslations?.length) {
      const location = getLocationByLang(locationTranslations, DEFAULT_LANGUAGE.code);
      const propertyType = getPropertyTypeByKey(propertyTypeKey);
      const propertyTypeForHref = getPropertyTypeForHref(propertyType, DEFAULT_LANGUAGE, propertyTypeSlug);
      linkTag.addLinks([
        getAlternateData('alternate', urlFunc(location, propertyID, propertyTypeForHref.toLowerCase()), 'x-default'),
      ]);
    }
    return linkTag;
  };

  inject('linkTags', {
    getLinkTag: () => buildLinkTag(),
    getCanonicalTag: (linkTag, href) => buildCanonicalTag(linkTag, href),
    getAlternateTagWithPropertyType: (linkTag, urlFunc, locationTranslations, propertyTypeKey, propertyTypeSlug) =>
      buildAlternateTagWithPropertyType(linkTag, urlFunc, locationTranslations, propertyTypeKey, propertyTypeSlug),
    getDefaultAlternateTagWithPropertyType: (
      linkTag,
      urlFunc,
      locationTranslations,
      propertyTypeKey,
      propertyTypeSlug
    ) =>
      buildDefaultAlternateTagWithPropertyType(
        linkTag,
        urlFunc,
        locationTranslations,
        propertyTypeKey,
        propertyTypeSlug
      ),
    getAlternateTagForProperty: (
      linkTag,
      urlFunc,
      locationTranslations,
      propertyTypeKey,
      propertyTypeSlug,
      propertyID
    ) =>
      buildAlternateTagForProperty(
        linkTag,
        urlFunc,
        locationTranslations,
        propertyTypeKey,
        propertyTypeSlug,
        propertyID
      ),
    getGenericPageAlternateTag: (linkTag, urlFunc, locationTranslations, pageUrlStructure) =>
      buildGenericPageAlternateTag(linkTag, urlFunc, locationTranslations, pageUrlStructure),
    getGenericPageDefaultAlternateTag: (linkTag, urlFunc, pageUrlStructure) =>
      buildGenericPageDefaultAlternateTag(linkTag, urlFunc, pageUrlStructure),
    getDefaultAlternateTagForProperty: (
      linkTag,
      urlFunc,
      locationTranslations,
      propertyTypeKey,
      propertyTypeSlug,
      propertyID
    ) =>
      buildDefaultAlternateTagForProperty(
        linkTag,
        urlFunc,
        locationTranslations,
        propertyTypeKey,
        propertyTypeSlug,
        propertyID
      ),
  });
};
