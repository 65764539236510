import { CURRENCIES } from '~/config/currencies';

export const currency = {
  format(amount, currencyCode, precision = 2) {
    const currencyInfo = CURRENCIES.filter((currency) => currency.code === currencyCode);
    const amountValue = amount || 0;
    const currencySymbol = currencyInfo[0] ? currencyInfo[0].symbol : currencyCode;

    return `${currencySymbol}${parseFloat(amountValue).toFixed(precision)}`;
  },
};

export default ({}, inject) => {
  inject('currency', currency);
};
