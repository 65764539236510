import mq from '~/mixins/mq';

export default {
  mixins: [mq],
  data() {
    return {
      accommodation: {
        header: this.$t('t_ACCOMMODATION'),
        links: [
          {
            text: this.$t('t_BLOG'),
            attrs: {
              href: this.$t('t_URLBLOG'),
            },
          },
          {
            type: 'property-type',
            text: this.$t('t_HOSTELS'),
            attrs: {
              href: this.$url.getWorldPageUrl({ propertyTypeSlug: this.$t('t_SLUGPROPERTYTYPEHOSTELS') }),
              spwahref: this.$t('t_SLUGPROPERTYTYPEHOSTELS'),
              rel: 'opener',
              target: '_self',
            },
          },
          {
            type: 'property-type',
            text: this.$t('t_HOTELS'),
            attrs: {
              href: this.$url.getWorldPageUrl({ propertyTypeSlug: this.$t('t_SLUGPROPERTYTYPEHOTELS') }),
              spwahref: this.$t('t_SLUGPROPERTYTYPEHOTELS'),
              rel: 'opener',
              target: '_self',
            },
          },
          {
            type: 'property-type',
            text: this.$t('t_GUESTHOUSE'),
            attrs: {
              href: this.$url.getWorldPageUrl({ propertyTypeSlug: this.$t('t_SLUGPROPERTYTYPEBNB') }),
              spwahref: this.$t('t_SLUGPROPERTYTYPEBNB'),
              rel: 'opener',
              target: '_self',
            },
          },
        ],
      },
      customerSupport: {
        header: this.$t('t_CUSTOMERSUPPORT'),
        links: [
          {
            text: this.$t('t_TALKTOUSTEXT'),
            attrs: {
              href: `${this.$t('t_CONTACTUSURL')}/requests/new?ticket_form_id=63361`,
            },
          },
          {
            text: this.$t('t_HELP'),
            attrs: {
              href: this.$t('t_CONTACTUSURL'),
            },
          },
        ],
      },
      quickLinks: {
        header: this.$t('t_QUICKLINKS'),
        links: [
          {
            text: this.$t('t_BLOG'),
            attrs: {
              href: this.$t('t_URLBLOG'),
              rel: 'noopener',
            },
          },
          {
            type: 'guarantee',
            text: this.$t('t_BOOKINGGUARANTEE'),
            attrs: {
              href: this.$url.getGenericPageUrl('guarantee'),
              rel: 'opener',
              target: '_self',
            },
          },
          {
            text: this.$t('t_HOSCARAWARDS'),
            attrs: {
              href: this.$t('t_HOSCARAWARDSURL'),
              rel: 'noopener',
            },
          },
          {
            text: this.$t('t_TOURSEXPERIENCESTEXT'),
            attrs: {
              href: this.$t('t_TOURSEXPERIENCESURL'),
              rel: 'noopener',
            },
          },
          {
            text: this.$t('t_SOLOTRAVELTEXT'),
            attrs: {
              href: this.$t('t_SOLOTRAVELURL'),
              rel: 'noopener',
            },
          },
          {
            text: this.$t('t_STUDENTDISCOUNTTEXT'),
            attrs: {
              href: this.$t('t_STUDENTDISCOUNTURL'),
              rel: 'noopener nofollow noreferrer sponsored',
              target: '_blank',
            },
          },
        ],
      },
      aboutUs: {
        header: this.$t('t_ABOUTUS'),
        links: [
          {
            text: this.$t('t_HWGROUPPLC'),
            attrs: {
              href: this.$t('t_HWGROUPPLCURL'),
            },
          },
          {
            text: this.$t('t_PRESS'),
            attrs: {
              href: this.$t('t_PRESSURL'),
            },
          },
          {
            text: this.$t('t_CAREERS'),
            attrs: {
              href: this.$t('t_CAREERSURL'),
            },
          },
        ],
      },
      workWithUs: {
        header: this.$t('t_WORKWITHUS'),
        links: [
          {
            text: this.$t('t_HOSTELSIGNUP'),
            attrs: {
              href: this.$t('t_HWSIGNUPURL'),
            },
          },
          {
            text: this.$t('t_AFFILIATEPROGRAM'),
            attrs: {
              href: this.$t('t_AFFILIATEPROGRAMURL'),
            },
          },
          {
            text: this.$t('t_INBOXLOGIN'),
            attrs: {
              href: this.$t('t_INBOXLOGINURL'),
            },
          },
        ],
      },
    };
  },
  computed: {
    getMobileQuickLinks() {
      return [this.accommodation];
    },

    getQuickLinks() {
      this.accommodation.links[0].show = this.isExtraSmallScreen;
      this.quickLinks.links[0].show = !this.isExtraSmallScreen;
      return [[this.accommodation, this.customerSupport], [this.quickLinks], [this.aboutUs, this.workWithUs]];
    },

    getAllQuickLinks() {
      this.accommodation.links[0].show = false;
      return {
        accommodation: this.accommodation,
        customerSupport: this.customerSupport,
        quickLinks: this.quickLinks,
        aboutUs: this.aboutUs,
        workWithUs: this.workWithUs,
      };
    },
  },
};
