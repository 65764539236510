export const state = /* istanbul ignore next */ () => ({
  data: {
    propertyType: null,
    continent: null,
    country: null,
    city: null,
    propertyId: null,
    propertyName: null,
    area: null,
    district: null,
  },
  propertyTypeKey: 'hostel',
  langUrls: {},
});

export const mutations = {
  setRoute(state, { propertyType, continent, country, city, propertyId, propertyName, area, district }) {
    state.data = {
      propertyType,
      continent,
      country,
      city,
      propertyId,
      propertyName,
      area,
      district,
    };
  },
  setPropertyTypeKey(state, propertyTypeKey) {
    state.propertyTypeKey = propertyTypeKey;
  },
  setAllRoutesByLang(state, langUrls) {
    state.langUrls = langUrls;
  },
};

export const getters = {
  getRouteFromLang: (state) => (lang) => {
    return state.langUrls[lang] || null;
  },
  isCityPage: (state) => {
    return !!state.data.city && !getters.isDistrictPage(state);
  },
  isDistrictPage: (state) => {
    return !!state.data.district;
  },
  isPropertyPage: (state) => {
    return state.data.propertyId > 0;
  },
};

export const actions = {
  setAllRoutesByLang({ commit }, langUrls) {
    commit('setAllRoutesByLang', langUrls);
  },
};
