import { instantiate } from '~/lib/lazyLoading';
import { Api } from '~/api';

export class ApiServiceLoader extends Api {
  constructor(name, http, prefixUrl, headers) {
    super(http, prefixUrl, headers);

    this.name = name;
  }

  async getServices(route) {
    return await instantiate(`api/${this.name}/${route}`, this);
  }
}
