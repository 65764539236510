const LANGUAGES = [
  {
    code: 'en_GB',
    iso: 'en-GB',
    tag: 'en',
    lang: 'en',
    url: 'en',
    baseUrl: 'BASE_URL_EN',
    name: 'English',
    enName: 'English',
  },
  {
    code: 'fr_FR',
    iso: 'fr-FR',
    tag: 'fr',
    lang: 'fr',
    url: 'fr',
    baseUrl: 'BASE_URL_FR',
    name: 'Français',
    enName: 'French',
  },
  {
    code: 'de_DE',
    iso: 'de-DE',
    tag: 'de',
    lang: 'de',
    url: 'de',
    baseUrl: 'BASE_URL_DE',
    name: 'Deutsch',
    enName: 'German',
  },
  {
    code: 'es_ES',
    iso: 'es-ES',
    tag: 'es',
    lang: 'es',
    url: 'es',
    baseUrl: 'BASE_URL_ES',
    name: 'Español',
    enName: 'Spanish',
  },
  {
    code: 'it_IT',
    iso: 'it-IT',
    tag: 'it',
    lang: 'it',
    url: 'it',
    baseUrl: 'BASE_URL_IT',
    name: 'Italiano',
    enName: 'Italian',
  },
  {
    code: 'pt_PT',
    iso: 'pt-PT',
    tag: 'pt-PT',
    lang: 'pt',
    url: 'pt',
    baseUrl: 'BASE_URL_PT',
    name: 'Português',
    enName: 'Portuguese',
  },
  {
    code: 'pt_BR',
    iso: 'pt-BR',
    tag: 'pt-BR',
    lang: 'br',
    url: 'br',
    baseUrl: 'BASE_URL_BR',
    name: 'Português (Brasil)',
    enName: 'Brazilian',
  },
  {
    code: 'sv_SE',
    iso: 'sv-SE',
    tag: 'sv',
    lang: 'sv',
    url: 'sv',
    baseUrl: 'BASE_URL_SV',
    name: 'Svenska',
    enName: 'Swedish',
  },
  {
    code: 'pl_PL',
    iso: 'pl-PL',
    tag: 'pl',
    lang: 'pl',
    url: 'pl',
    baseUrl: 'BASE_URL_PL',
    name: 'Polski',
    enName: 'Polish',
  },
  {
    code: 'fi_FI',
    iso: 'fi-FI',
    tag: 'fi',
    lang: 'fi',
    url: 'fi',
    baseUrl: 'BASE_URL_FI',
    name: 'Suomi',
    enName: 'Finnish',
  },
  {
    code: 'da_DK',
    iso: 'da-DK',
    tag: 'da',
    lang: 'da',
    url: 'da',
    baseUrl: 'BASE_URL_DA',
    name: 'Dansk',
    enName: 'Danish',
  },
  {
    code: 'nl_NL',
    iso: 'nl-NL',
    tag: 'nl',
    lang: 'nl',
    url: 'nl',
    baseUrl: 'BASE_URL_NL',
    name: 'Nederlands',
    enName: 'Dutch',
  },
  {
    code: 'nb_NO',
    iso: 'nb-NO',
    tag: 'no',
    lang: 'nb',
    url: 'no',
    baseUrl: 'BASE_URL_NB',
    name: 'Norsk',
    enName: 'Norwegian',
  },
  {
    code: 'cs_CZ',
    iso: 'cs-CZ',
    tag: 'cs',
    lang: 'cs',
    url: 'cs',
    baseUrl: 'BASE_URL_CS',
    name: 'český',
    enName: 'Czech',
  },
  {
    code: 'ru_RU',
    iso: 'ru-RU',
    tag: 'ru',
    lang: 'ru',
    url: 'ru',
    baseUrl: 'BASE_URL_RU',
    name: 'Русский',
    enName: 'Russian',
  },
  {
    code: 'tr_TR',
    iso: 'tr-TR',
    tag: 'tr',
    lang: 'tr',
    url: 'tr',
    baseUrl: 'BASE_URL_TR',
    name: 'Türk',
    enName: 'Turkish',
  },
  {
    code: 'zh_CN',
    iso: 'zh-CN',
    tag: 'zh',
    lang: 'zh',
    url: 'zh',
    baseUrl: 'BASE_URL_ZH',
    name: '中文',
    enName: 'Chinese',
  },
  {
    code: 'ja_JP',
    iso: 'ja-JP',
    tag: 'ja',
    lang: 'jp',
    url: 'ja',
    baseUrl: 'BASE_URL_JA',
    name: '日本語',
    enName: 'Japanese',
  },
  {
    code: 'ko_KR',
    iso: 'ko-KR',
    tag: 'ko',
    lang: 'ko',
    url: 'ko',
    baseUrl: 'BASE_URL_KO',
    name: '한국어',
    enName: 'Korean',
  },
];

const DEFAULT_LANGUAGE = LANGUAGES[0];

module.exports = {
  LANGUAGES,
  DEFAULT_LANGUAGE,
};
