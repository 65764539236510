import config from '~/config/structured.markup';

export class StructuredMarkup {
  constructor() {
    this.language = config.defaultLanguageCode;
    this.schema = this.getBaseSchema();
  }

  setLanguage(languageCode) {
    this.language = languageCode && config.links?.[languageCode] ? languageCode : config.defaultLanguageCode;
  }

  getFullSchema() {
    return this.schema;
  }

  getBaseSchema() {
    return {
      // Website schema
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          name: 'Hostelworld',
          url: config.baseUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: config.baseUrl + '/find/keywordsuggestions?search_keywords={search_term_string}&internalsearch=yes',
            'query-input': 'required name=search_term_string',
          },
        },
      ],
    };
  }

  addDefault(languageCode) {
    this.setLanguage(languageCode);

    this.addOrganizationSchema();
    this.addMobileApplicationSchema();
  }

  addOrganizationSchema() {
    // Organization schema
    this.addGraph({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Hostelworld',
      logo: config.logo,
      url: config.baseUrl,
      sameAs: this.buildSameAsLinks(),
    });
  }

  addMobileApplicationSchema() {
    const links = config.links[this.language];

    // Software Application schema - Android
    this.addGraph({
      '@type': 'SoftwareApplication',
      name: 'Hostelworld',
      operatingSystem: 'ANDROID',
      applicationCategory: 'https://schema.org/MobileApplication',
      downloadUrl: links.android,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: config.rating.android.value,
        ratingCount: config.rating.android.count,
      },
      offers: {
        '@type': 'Offer',
        price: '0.00',
        priceCurrency: '$',
      },
    });

    // Software Application schema - iOS
    this.addGraph({
      '@type': 'SoftwareApplication',
      name: 'Hostelworld',
      operatingSystem: 'IOS',
      applicationCategory: 'https://schema.org/MobileApplication',
      downloadUrl: links.iOs,
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: config.rating.iOs.value,
        ratingCount: config.rating.iOs.count,
      },
      offers: {
        '@type': 'Offer',
        price: '0.00',
        priceCurrency: '$',
      },
    });
  }

  addGraph(graphItem) {
    this.schema['@graph'].push(graphItem);
  }

  buildSameAsLinks() {
    return [
      this.language === 'zh_CN' && config.links[this.language].weibo,
      this.language === 'ko_KR' && config.links[this.language].naver,
      this.language !== 'zh_CN' && config.links[config.defaultLanguageCode].facebook,
      this.language !== 'zh_CN' &&
        (config.links[this.language] && config.links[this.language].twitter
          ? config.links[this.language].twitter
          : config.links[config.defaultLanguageCode].twitter),
      this.language !== 'zh_CN' && config.links[config.defaultLanguageCode].youtube,
      this.language !== 'zh_CN' && config.links[config.defaultLanguageCode].instagram,
      config.links[config.defaultLanguageCode].linkedin,
      config.links[config.defaultLanguageCode].crunchbase,
    ].filter(Boolean);
  }
}

export default ({ app }, inject) => {
  const structMarkup = new StructuredMarkup();
  inject('structuredMarkup', structMarkup);
};
