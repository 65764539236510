export const segment = {
  install: function (writeKey) {
    const analytics = (window.analytics = window.analytics || []);

    if (analytics.initialize) {
      return;
    }

    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return;
    }

    analytics.invoked = true;

    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ];

    analytics.factory = function (e) {
      return function () {
        const t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        analytics.push(t);
        return analytics;
      };
    };

    for (let e = 0; e < analytics.methods.length; e++) {
      const key = analytics.methods[e];
      analytics[key] = analytics.factory(key);
    }

    analytics.SNIPPET_VERSION = '4.15.3';

    analytics.load = function (key, e) {
      const t = document.createElement('script');
      t.type = 'text/javascript';
      t.async = !0;
      t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
      const n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(t, n);
      analytics._loadOptions = e;
    };

    analytics.load(writeKey);
    return analytics;
  },
};

export default ({ app }, inject) => {
  // Client site only
  if (process.server) return;

  if (app.$optimizely.isFeatureEnabled('web_segment')) {
    segment.install(app.$env.SEGMENT_JS);
    inject('segment', window.analytics);
  }
};
