import Vue from 'vue';

const handler = '_hw_outside_handler';

const bind = (el, binding, vnode) => {
  unbind(el, binding);

  const callback = binding.value || null;

  if (typeof callback !== 'function') {
    return;
  }

  /**
   * Click events are dispatched as macrotasks and Vue directives are considered as microtasks in ES6
   * and they are executed after every callback as long as no other javascript is mid-execution at the end of each task.
   * The setTimeout ensures that everything happens like we want.
   * @see See [Macro and microtask in ECMAScript](https://jakearchibald.com/2015/tasks-microtasks-queues-and-schedules/)
   */
  let taskEnded = false;
  setTimeout(function () {
    taskEnded = true;
  }, 0);

  el[handler] = (event) => {
    const path = event.path || (event.composedPath ? event.composedPath() : undefined);
    if (taskEnded && (path ? path.indexOf(el) < 0 : !el.contains(event.target))) {
      return vnode && vnode.context ? callback.call(vnode.context, event) : '';
    }
  };

  if (typeof binding.modifiers === 'object') {
    const events = Object.keys(binding.modifiers);

    events.forEach((event) => {
      document.addEventListener(event, el[handler], false);
    });
  }
};

const unbind = (el, binding) => {
  if (typeof binding.modifiers === 'object') {
    const events = Object.keys(binding.modifiers);

    events.forEach((event) => {
      document.removeEventListener(event, el[handler], false);
    });
  }

  delete el[handler];
};

const directive = {
  bind,
  update(element, binding, vnode) {
    bind(element, binding, vnode);
  },
  unbind,
};

Vue.directive('outside', directive);
