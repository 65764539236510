import { version } from '../../package';
import { version as wdsVersion } from '@wds/components/package.json';

const whiteList = [
  'RELEASE_ENV',
  'STATIC_URL',
  'HOSTELWORLD_API_URL',
  'HOSTELWORLD_API_URL_INTERNAL',
  'HOSTELWORLD_URL',
  'HOSTELWORLD_URL_INTERNAL',
  'AUTOCOMPLETE_API_URL',
  'AUTOCOMPLETE_API_URL_INTERNAL',
  'MICROSITE_API_URL',
  'MICROSITE_API_URL_INTERNAL',
  'STATIC_PAGES_API_URL',
  'STATIC_PAGES_API_URL_INTERNAL',
  'SPWA_REDIRECTION_API',
  'SS_API_URL',
  'PROPERTY_EVENTS_SERVICE_URL',
  'PROPERTY_EVENTS_SERVICE_URL_INTERNAL',
  'COOKIE_DOMAIN',
  'COOKIE_SECURE',
  'COOKIE_SESSION_DOMAIN',
  'DEFAULT_BASE_URL',
  'BASE_URL_PROTOCOL',
  'BASE_URL_EN',
  'BASE_URL_FR',
  'BASE_URL_DE',
  'BASE_URL_ES',
  'BASE_URL_IT',
  'BASE_URL_PT',
  'BASE_URL_BR',
  'BASE_URL_SV',
  'BASE_URL_PL',
  'BASE_URL_FI',
  'BASE_URL_DA',
  'BASE_URL_NL',
  'BASE_URL_NB',
  'BASE_URL_CS',
  'BASE_URL_RU',
  'BASE_URL_TR',
  'BASE_URL_ZH',
  'BASE_URL_JA',
  'BASE_URL_KO',
  'STATIC_BASE_URL_EN',
  'STATIC_BASE_URL_FR',
  'STATIC_BASE_URL_DE',
  'STATIC_BASE_URL_ES',
  'STATIC_BASE_URL_IT',
  'STATIC_BASE_URL_PT',
  'STATIC_BASE_URL_BR',
  'STATIC_BASE_URL_SV',
  'STATIC_BASE_URL_PL',
  'STATIC_BASE_URL_FI',
  'STATIC_BASE_URL_DA',
  'STATIC_BASE_URL_NL',
  'STATIC_BASE_URL_NB',
  'STATIC_BASE_URL_CS',
  'STATIC_BASE_URL_RU',
  'STATIC_BASE_URL_TR',
  'STATIC_BASE_URL_ZH',
  'STATIC_BASE_URL_JA',
  'STATIC_BASE_URL_KO',
  'GTM_ID',
  'OPTIMIZELY_SDK_KEY',
  'SENTRY_DSN',
  'MEMCACHED',
  'SEGMENT_JS',
  'APIGEE_KEY',
  'STATICWEB_URL',
];

export default (req, res, next) => {
  if (!req.env) {
    req.env = {};
  }

  Object.keys(process.env).forEach((key) => {
    if (whiteList.includes(key)) {
      req.env[key] = process.env[key];
    }
  });

  req.env['WDS_VERSION'] = wdsVersion;
  req.env['RELEASE_VERSION'] = version;

  next();
};
