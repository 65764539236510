export const APPDOWNLOADBANNERLINKS = {
  WORLDWIDE: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/u3sohpa3',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/zfz2inhe',
  },

  CONTINENT: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/q5gyjowy',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/0tz4sdmj',
  },

  COUNTRY: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/43gfut9m',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/f5168gpb',
  },

  AREA: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/pwbe98go',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/ck5odmzm',
  },

  REGION: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/hhue25yp',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/24o49vyu',
  },

  CITY: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/1r43h27p',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/pte4dvbo',
  },

  DISTRICT: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/c9sj2e05',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/0adndjf8',
  },

  PROPERTY: {
    VARIATION_A: 'https://hostelworld.onelink.me/IdiK/fivqsa6v',
    VARIATION_B: 'https://hostelworld.onelink.me/IdiK/h8b6frh3',
  },

  MENU: 'https://hostelworld.onelink.me/IdiK/l5wn8um8',
};
