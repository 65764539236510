export default {
  computed: {
    isExtraSmallScreen() {
      return this.$mq === 'xs';
    },
    isSmallScreen() {
      return this.$mq === 'sm';
    },
    isMediumScreen() {
      return this.$mq === 'md';
    },
    isBelowMediumScreen() {
      return this.isExtraSmallScreen || this.isSmallScreen;
    },
    isSmallOrMediumScreen() {
      return this.isExtraSmallScreen || this.isSmallScreen || this.isMediumScreen;
    },
  },
};
