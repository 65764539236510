/* eslint-disable no-control-regex */

const pairSplitRegExp = /; */;
const fieldContentRegExp = /^[\u0009\u0020-\u007e\u0080-\u00ff]+$/;

/**
 * Parse the given cookie string into an object keyed by cookie name.
 *
 * @param {string} str
 * @return {object}
 */
export const parse = (str) => {
  if (typeof str !== 'string') {
    throw new TypeError('str must be a string');
  }

  const cookies = {};
  const pairs = str.split(pairSplitRegExp);

  pairs.forEach((pair) => {
    let [key, value] = pair.split('=');

    // Not a key-value pair.
    if (key === undefined || value === undefined) {
      return;
    }

    key = key.trim();
    value = value.trim();

    // Quoted value.
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }

    if (cookies[key] === undefined) {
      cookies[key] = decodeURIComponent(value);
    }
  });

  return cookies;
};

/**
 * @typedef {object} SerializeOptions
 * @property {string} [domain]
 * @property {string} [path]
 * @property {number} [maxAge]
 * @property {string} expires
 * @property {boolean} secure
 * @property {'None'|'Strict'|'Lax'} sameSite
 */

/**
 * Serialize data into a cookie string.
 *
 * @param {string} name
 * @param {string} value
 * @param {SerializeOptions} [opts]
 */
export const serialize = (name, value, opts = {}) => {
  value = encodeURIComponent(value);
  let str = `${name}=${value}`;

  if (!fieldContentRegExp.test(name)) {
    throw new TypeError('name is invalid');
  }

  const appendToCookie = [];
  if (opts.domain) {
    if (!fieldContentRegExp.test(opts.domain)) {
      throw new TypeError('domain is invalid');
    }
    appendToCookie.push(`domain=${opts.domain}`);
  }

  if (opts.path) {
    if (!fieldContentRegExp.test(opts.path)) {
      throw new TypeError('path is invalid');
    }
    appendToCookie.push(`path=${opts.path}`);
  }

  if (opts.maxAge) {
    if (typeof opts.maxAge !== 'number') {
      throw new TypeError('maxAge must be a number');
    }
    appendToCookie.push(`max-age=${opts.maxAge}`);
  }

  if (opts.expires) {
    if (!fieldContentRegExp.test(opts.expires)) {
      throw new TypeError('expires must be a string');
    }
    appendToCookie.push(`expires=${opts.expires}`);
  }

  if (opts.secure) {
    if (typeof opts.secure !== 'boolean') {
      throw new TypeError('secure must be a boolean');
    }
    appendToCookie.push('secure');
  }

  if (opts.sameSite) {
    appendToCookie.push(`SameSite=${opts.sameSite}`);
  }

  return appendToCookie.length ? formatCookie(str, appendToCookie) : str;
};

/**
 * @param {string} baseCookie
 * @param {Array<any>} append
 * @example ('x=a', ['y', 'z']) => 'x=a; y; z'
 * @returns {string} formatted and joined cookie
 */
export const formatCookie = (baseCookie, append) => {
  return baseCookie + `; ${append.join('; ')}`;
};
