<template>
  <wds-footer
    ref="wds-footer"
    v-bind="getFooterData"
    @languagePickerClick="toggleLanguagePicker(true)"
    @currencyPickerClick="toggleCurrencyPicker(true)"
  />
</template>

<script>
import { WdsFooter } from '@wds/components';
import socialMediaLinks from './sections/social-media-links';
import quickLinks from './sections/quick-links';
import legalLinks from './sections/legal-links';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Footer',

  mixins: [legalLinks, socialMediaLinks, quickLinks],

  components: {
    WdsFooter,
  },

  props: {
    isShortVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      getCurrentLanguage: 'session/getCurrentLanguage',
      getCurrentCurrency: 'session/getCurrentCurrency',
    }),

    getFooterData() {
      return {
        isShortVersion: this.isShortVersion,
        currentLanguage: this.getCurrentLanguage.name,
        currentCurrency: this.getCurrentCurrency.code,
        socialMediaLinks: this.getSocialNetworks,
        quickLinks: this.getQuickLinks,
        legalLinks: this.legalLinks,
        cncLogo: {
          show: true,
        },
        translations: {
          tFollowUs: this.$t('t_FOLLOWUSON'),
        },
      };
    },
  },

  methods: {
    ...mapActions({
      toggleLanguagePicker: 'session/toggleLanguagePicker',
      toggleCurrencyPicker: 'session/toggleCurrencyPicker',
    }),
  },

  mounted() {
    const legalLinks = this.$refs['wds-footer'].$el.querySelector('.footer-area.legal-links');
    if (legalLinks) {
      legalLinks.setAttribute('id', 'teconsent');
    }
  },
};
</script>

<style lang="scss">
.truste_cursor_pointer {
  display: inline-flex;
  align-items: center;
  min-height: 3rem;
  min-width: 2rem;
  padding: 0 0.5rem;
  color: $wds-color-white;

  &:hover {
    text-decoration: none;
    color: $wds-color-white;
  }
}
</style>
