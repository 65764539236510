export default class TranscodeInterpolate {
  /**
   * @param {string} message
   * @param {object} values
   */
  interpolate(message, values) {
    if (!values) {
      return;
    }
    const interpolationKeys = Object.keys(values);
    if (typeof message === 'undefined' || interpolationKeys.length === 0) {
      return message;
    }
    interpolationKeys.forEach((key) => {
      // create case-insensitive regex
      const reg = new RegExp(`##${key}##`, 'ig');
      message = message.replace(reg, values[key]);
    });
    return message;
  }

  toJSON() {
    return { ...this };
  }
}
