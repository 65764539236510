/**
 * Lazy-load a named module and create an instance of the default export class.
 * Caches results so that a module is only ever loaded once.
 */
const cache = {};

export const instantiate = async (mod, ...args) => {
  if (cache[mod]) {
    return cache[mod];
  }

  const res = await import(/* webpackMode: "lazy" */ '~/' + mod + '/index');
  const instance = new res.default(...args);
  cache[mod] = instance;

  return instance;
};
