import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

// dayjs is immutable, it always returns a copy of the original object
export class Time {
  static get weekDays() {
    return {
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 0,
      NUMBER_OF_DAYS: 7,
    };
  }

  static get dateFormats() {
    return {
      FORMAT_INTERNATIONAL: 'YYYY-MM-DD',
      DAY_MONTH: 'DD MMM',
      DAY_MONTH_YEAR: 'DD MMM YYYY',
    };
  }

  static formatDate(date, formatString = Time.dateFormats.FORMAT_INTERNATIONAL) {
    return date.format(formatString);
  }

  constructor(date, lang = 'en') {
    const langToReplace = [
      { from: 'pt-PT', to: 'pt' },
      { from: 'no', to: 'nb' },
    ];
    const language =
      langToReplace
        .map((lg) => (lg.from === lang ? lg.to : null))
        .filter((lg) => lg)
        .shift() || lang;
    const selectedLang = require(`dayjs/locale/${language.toLowerCase()}.js`);
    dayjs.locale({
      ...selectedLang,
      weekStart: 1,
    });
    this.date = dayjs(date);
  }

  get tomorrow() {
    return this.addNDays(1);
  }

  get tonightRange() {
    return [this.date, this.date.add(1, 'day')];
  }

  get nextWeekendRange() {
    if (!this.isWeekend()) {
      return [this.date.day(Time.weekDays.FRIDAY), this.date.day(Time.weekDays.SUNDAY + Time.weekDays.NUMBER_OF_DAYS)];
    }
  }

  get secondWeekendRange() {
    const skipWeek = this.date.format('d') !== String(Time.weekDays.SUNDAY) ? Time.weekDays.NUMBER_OF_DAYS : 0;

    return [
      this.date.day(Time.weekDays.FRIDAY + skipWeek),
      this.date.day(Time.weekDays.SUNDAY + Time.weekDays.NUMBER_OF_DAYS + skipWeek),
    ];
  }

  addNDays(n) {
    return this.date.add(n, 'day');
  }

  dayDiff(otherDate) {
    return this.date.diff(otherDate, 'day');
  }

  isWeekend() {
    return (
      this.date.format('d') === String(Time.weekDays.SATURDAY) || this.date.format('d') === String(Time.weekDays.SUNDAY)
    );
  }

  toJSON() {
    return { ...this };
  }
}
