/**
 * Sets a header value in the response object.
 * May be called repeatedly to send multiple headers with the same name.
 *
 * @param {import('http').ServerResponse} res
 * @param {string} name
 * @param {string | number | string[]} value
 */
export const setHeader = (res, name, value) => {
  let headers = getResponseHeader(res, name).concat(value);
  headers = headers.length === 1 ? headers[0] : headers;
  res.setHeader(name, headers);
};

const getResponseHeader = (res, name) => {
  const headers = res.getHeader(name);

  if (headers === undefined) {
    return [];
  }

  return [].concat(headers);
};

const httpErrors = {
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

const errorPage = (statusCode, message) => ({
  statusCode,
  message,
});

export const notFoundErrorPage = (message) => errorPage(httpErrors.NOT_FOUND, message);

export const internalServerErrorPage = (message) => errorPage(httpErrors.INTERNAL_SERVER_ERROR, message);

export const getHttpErrors = (error) => httpErrors[error] || null;
