<template>
  <div class="page-container">
    <div class="wrapper">
      <nav-bar class="nav" />
    </div>

    <main class="content-wrapper">
      <div class="content">
        <div class="wrapper">
          <div class="error-img">
            <img src="/st/images/flamingo.svg" :alt="$t('t_ERRORFLAMINGO')" :title="$t('t_ERRORFLAMINGO')" />
          </div>

          <div class="error-text">
            <h1>{{ $t('t_ERROROOPS') }}</h1>
            <h2>{{ $t('t_ERRORPOWERNAP') }}</h2>
            <h3>{{ $t('t_ERRORSERVERERROR500') }}</h3>

            <wds-button-ghost
              type="button"
              :text="$t('t_REFRESH')"
              icon-start="free-cancelation"
              target="_blank"
              rel="noopener"
              @click="refresh"
            />
          </div>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import NavBar from '~/components/common/NavBar';
import Footer from '~/components/common/Footer';
import { WdsButtonGhost } from '@wds/components';

export default {
  name: 'error500',

  components: {
    Footer,
    NavBar,
    WdsButtonGhost,
  },

  mounted() {
    this.trackPageViews();
  },

  methods: {
    async trackPageViews() {
      this.tracking = await this.$tracking.GenericPage();

      const trackingInfo = {
        gtm: {
          gtmPageName: 'error500Page',
        },
        segment: {
          event_name: '500',
          page_type: '500',
        },
      };

      await this.tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);
    },

    refresh() {
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  margin: 0;
  grid-template-rows: auto 1fr auto;

  .wrapper {
    padding: 0 $wds-spacing-m;
    width: 100%;
  }

  .content-wrapper {
    background-color: $wds-color-teal-light;
    padding: 0 0 wds-rem(70px) 0;
    position: relative;
    width: 100%;
    clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);

    .content {
      padding: $wds-spacing-m;
      display: flex;
      flex-direction: row;

      @include tablet {
        padding-bottom: wds-rem(62px);
      }

      @include desktop {
        margin-top: wds-rem(35px);
      }

      .wrapper {
        display: flex;
        flex-direction: column;

        @include tablet {
          flex-direction: row-reverse;
        }

        @include tablet-large {
          max-width: wds-rem(960px);
          margin: 0 auto;
        }
      }

      .error-img {
        width: 100%;

        @include tablet {
          margin-top: wds-rem(30px);
          margin-bottom: wds-rem(30px);
          width: 80%;
        }
      }

      .error-text {
        align-items: center;
        display: flex;
        flex-direction: column;

        h1 {
          margin-bottom: $wds-spacing-s;

          @include title-1-bld;
        }

        h2 {
          margin-bottom: $wds-spacing-s;

          @include title-3-bld;
        }

        h3 {
          margin-bottom: $wds-spacing-s;

          @include title-6-reg;
        }

        @include tablet {
          align-items: flex-start;
          display: flex;
          flex-direction: column;

          text-align: left;
          margin-top: 15%;
          margin-bottom: 30px;

          h3 {
            margin-bottom: $wds-spacing-xl;
          }
        }

        @include desktop {
          h2 {
            @include title-2-bld;
          }
        }
      }
    }
  }

  footer {
    margin-top: wds-rem(-70px);

    @include tablet {
      margin-top: wds-rem(-80px);
    }
  }
}
</style>
