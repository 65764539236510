import { ApiServiceLoader } from '~/api/serviceLoader';
import { SP_API, HW_API, HW_RWD, MS_API, RD_API } from '~/data/apis';
import { DEFAULT_LANGUAGE } from '~/config/languages';
/**
 * The api plugin injects ready-to-use API clients to the $api property.
 * APIs are loaded and instantiated asynchronously on demand such that only the APIs actually used in a particular request are loaded.
 *
 * Register new APIs by adding accessors to the api object. The typescript definition file should be updated accordingly.
 *
 * @param {import('@nuxt/vue-app').Context}
 * @param inject
 */
export default ({ app: { $http, $env, $config }, store }, inject) => {
  const languageObject = store.state?.session?.language || DEFAULT_LANGUAGE;
  const language = languageObject?.lang;
  const rwdUrlBaseUrl = languageObject?.baseUrl;

  const apiList = {
    [SP_API]: {
      url: process.server ? $env.STATIC_PAGES_API_URL_INTERNAL : $env.STATIC_PAGES_API_URL,
      headers: {
        'Accept-Language': language,
        'api-key': $env.APIGEE_KEY,
      },
    },
    [HW_API]: {
      url: process.server ? $env.HOSTELWORLD_API_URL_INTERNAL : $env.HOSTELWORLD_API_URL,
      headers: {
        'Accept-Language': language,
        'api-key': $env.APIGEE_KEY,
      },
    },
    [MS_API]: {
      url: process.server ? $env.MICROSITE_API_URL_INTERNAL : $env.MICROSITE_API_URL,
      headers: {
        'Accept-Language': language,
        'api-key': $env.APIGEE_KEY,
      },
    },
    [HW_RWD]: {
      url: `${$env.BASE_URL_PROTOCOL}://${$env[rwdUrlBaseUrl]}`,
      headers: {
        'Accept-Language': language,
      },
    },
    [RD_API]: {
      url: $env.SPWA_REDIRECTION_API,
    },
  };

  const apis = {};

  const getApi = (name) => {
    const api = apiList[name];
    if (!api) throw new Error("API plugin: Api with that name doesn't exist.");

    if (!apis[name]) {
      apis[name] = new ApiServiceLoader(name, $http, api.url, api.headers);
    }

    return apis[name];
  };

  inject('api', {
    getApi,
    getHwApi: () => getApi(HW_API),
    getSpApi: () => getApi(SP_API),
    getHwRwd: () => getApi(HW_RWD),
    getMsApi: () => getApi(MS_API),
    getRdApi: () => getApi(RD_API),
  });
};
