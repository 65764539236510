import consola from 'consola';

export class LocalStorageAdapter {
  storage = localStorage;

  constructor(storageName) {
    this.storageName = storageName;
  }

  replaceItem(attribution) {
    this.storage.setItem(this.storageName, JSON.stringify(attribution));
  }

  getItem() {
    return JSON.parse(this.storage.getItem(this.storageName));
  }

  removeItem() {
    this.storage.removeItem(this.storageName);
  }

  getInstance() {
    const uid = new Date(Date.now());
    try {
      this.storage.setItem(uid, uid);
      const result = this.storage.getItem(uid) === uid.toString();
      this.storage.removeItem(uid);

      if (result) {
        return this;
      }
    } catch (exception) {
      consola.error(exception);
    }
  }
}

export const channelAttributionStorage = () => new LocalStorageAdapter('channelAttribution');
