export class UrlLanguage {
  constructor(req, currentUrl, path, languages) {
    this.env = req.env || req.$env;
    this.currentUrl = this.hasProtocol(currentUrl)
      ? `${currentUrl}`
      : `${this.env['BASE_URL_PROTOCOL']}://${currentUrl}`;
    this.path = path;
    this.languages = languages;
    this.defaultBaseUrl = this.env['DEFAULT_BASE_URL'];
  }

  hasProtocol(url) {
    const regex = new RegExp('^http(s)://');
    return regex.test(url);
  }

  getLanguage() {
    return (
      this.languages.filter((lang) => this.currentUrl.includes(this.env[`STATIC_${lang.baseUrl}`])).pop() ||
      this.languages.filter((lang) => this.currentUrl.includes(this.env[lang.baseUrl])).shift() ||
      this.languages
        .filter((lang) => this.currentUrl.includes(`//${this.removeText(this.env[lang.baseUrl], 'www.')}`))
        .shift()
    );
  }

  removeText(value, textToRemove) {
    if (typeof value === 'string' && typeof textToRemove === 'string') {
      return value.replace(textToRemove, '');
    }
  }

  addSlashOnEnd(value) {
    const regex = new RegExp('/$');
    return !value || regex.test(value) ? value : `${value}/`;
  }

  buildLangUrl(lang) {
    const language = lang === 'jp' ? 'ja' : lang;
    const baseUrl = `BASE_URL_${language.toUpperCase()}`;
    const currentURL = new URL(this.currentUrl);
    const protocol = `${currentURL.protocol}//`;
    const domain = this.env[baseUrl];
    const port = currentURL.port ? `:${currentURL.port}` : '';
    const pathName = this.path;
    const search = currentURL.search;
    const newUrl = `${domain}${port}/st/${pathName}${search}`.replace('//', '/');

    return `${protocol}${newUrl}`;
  }
}
