// Values for key are: hostel, hotel, guesthouse, campsite, apartment (to be used on API calls)
const PROPERTY_TYPES = [
  {
    id: 1,
    type: 'HOSTELS',
    key: 'hostel',
    schema: 'Hostel',
    transcode: 't_PROPERTYTYPEHOSTELS',
    transcode_s: 't_PROPERTYTYPEHOSTEL',
    slug: 't_SLUGPROPERTYTYPEHOSTELS',
    limit: 30,
  },
  {
    id: 2,
    type: 'HOTELS',
    key: 'hotel',
    schema: 'Hotel',
    transcode: 't_PROPERTYTYPEHOTELS',
    transcode_s: 't_PROPERTYTYPEHOTEL',
    slug: 't_SLUGPROPERTYTYPEHOTELS',
    limit: 30,
  },
  {
    id: 3,
    type: 'GUESTHOUSES',
    key: 'guesthouse',
    schema: 'BedAndBreakfast',
    transcode: 't_PROPERTYTYPEBNBS',
    transcode_s: 't_PROPERTYTYPEBNB',
    slug: 't_SLUGPROPERTYTYPEBNB',
    limit: 30,
  },
];

const PROPERTY_TYPE_DEFAULT = PROPERTY_TYPES[0];

module.exports = {
  PROPERTY_TYPES,
  PROPERTY_TYPE_DEFAULT,
};
