import { ApiError } from './exceptions';

export class Api {
  constructor(http, prefixUrl, headers = {}) {
    this.api = http.create({
      prefixUrl,
    });
    this.defaultHeaders = headers;
  }

  get(path, searchParams = {}, headers = {}, otherOptions = {}) {
    return this.formatResponse(
      this.api.$get(path.replace(/\/?$/, '/'), {
        searchParams,
        headers: { ...this.defaultHeaders, ...headers },
        ...otherOptions,
      })
    );
  }

  post(path, payload, headers = {}) {
    return this.formatResponse(
      this.api.$post(path, payload, {
        headers: { ...this.defaultHeaders, ...headers },
      })
    );
  }

  patch(path, payload, headers = {}) {
    return this.formatResponse(
      this.api.$patch(path, payload, {
        headers: { ...this.defaultHeaders, ...headers },
      })
    );
  }

  put(path, payload, headers = {}) {
    return this.formatResponse(
      this.api.$put(path, payload, {
        headers: { ...this.defaultHeaders, ...headers },
      })
    );
  }

  delete(path, headers = {}) {
    return this.formatResponse(
      this.api.$delete(path, {
        headers: { ...this.defaultHeaders, ...headers },
      })
    );
  }

  async formatResponse(fn) {
    try {
      const response = await fn;

      return {
        data: response.data || response,
      };
    } catch (e) {
      if (e.response) {
        throw new ApiError(e.response.data, e.response.status);
      }

      throw new ApiError(e.name, e.code);
    }
  }
}
