export default {
  defaultLanguageCode: 'en_GB',
  baseUrl: 'https://www.hostelworld.com',
  logo: 'https://a.hwstatic.com/image/upload/f_auto,q_auto/hw/logos/hw_logo.svg',
  rating: {
    iOs: { value: '4+', count: 1140 },
    android: { value: '4.2', count: 8963 },
  },
  links: {
    en_GB: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app',
      facebook: 'https://www.facebook.com/hostelworld',
      twitter: 'https://twitter.com/hostelworld',
      youtube: 'https://www.youtube.com/user/hostelworld',
      instagram: 'https://www.instagram.com/hostelworld/',
      linkedin: 'https://www.linkedin.com/company/hostelworld-com',
      crunchbase: 'https://www.crunchbase.com/organization/hostelworld-com',
    },
    cs_CZ: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app',
      twitter: 'https://twitter.com/hostelworld',
    },
    da_DK: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app',
      twitter: 'https://twitter.com/hostelworld',
    },
    de_DE: {
      iOs: 'https://itunes.apple.com/de/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=de-DE',
      twitter: 'https://twitter.com/hostelworldde',
    },
    es_ES: {
      iOs: 'https://itunes.apple.com/es/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=es-ES',
      twitter: 'https://twitter.com/Hostelworld_es',
    },
    fr_FR: {
      iOs: 'https://itunes.apple.com/fr/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=fr-FR',
      twitter: 'https://twitter.com/Hostelworld_FR',
    },
    ko_KR: {
      iOs: 'https://itunes.apple.com/kr/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=ko-KR',
      twitter: 'https://twitter.com/hostelworld',
      naver: 'https://blog.naver.com/hostel_world',
    },
    it_IT: {
      iOs: 'https://itunes.apple.com/it/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=it-IT',
      twitter: 'https://twitter.com/Hostelworld_IT',
    },
    nl_NL: {
      iOs: 'https://itunes.apple.com/nl/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=nl-NL',
      twitter: 'https://twitter.com/hostelworld',
    },
    nb_NO: {
      iOs: 'https://itunes.apple.com/no/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=no',
      twitter: 'https://twitter.com/hostelworld',
    },
    pl_PL: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=pl-PL',
      twitter: 'https://twitter.com/hostelworld',
    },
    pt_BR: {
      iOs: 'https://itunes.apple.com/br/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=br_br',
      twitter: 'https://twitter.com/hostelworldBR',
    },
    pt_PT: {
      iOs: 'https://itunes.apple.com/pt/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=pt_br',
      twitter: 'https://twitter.com/Hostelworld_pt',
    },
    ru_RU: {
      iOs: 'https://itunes.apple.com/ru/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=ru-RU',
      twitter: 'https://twitter.com/hostelworld',
    },
    ja_JP: {
      iOs: 'https://itunes.apple.com/jp/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=ja-JP',
      twitter: 'https://twitter.com/hostelworldJPN',
    },
    sv_SE: {
      iOs: 'https://itunes.apple.com/se/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=sv-SE',
      twitter: 'https://twitter.com/hostelworld',
    },
    fi_FI: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app',
      twitter: 'https://twitter.com/hostelworld',
    },
    tr_TR: {
      iOs: 'https://itunes.apple.com/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app',
      twitter: 'https://twitter.com/hostelworld',
    },
    zh_CN: {
      iOs: 'https://itunes.apple.com/cn/app/hostelworld-book-hostels-budget/id348890820?mt=8',
      android: 'https://play.google.com/store/apps/details?id=com.hostelworld.app&amp;hl=zh-CN',
      weibo: 'http://e.weibo.com/hostelworld',
    },
  },
};
