import Gdpr from '~/lib/gdpr/index';

export function GoogleTagManager(context) {
  const instance = window.dataLayer || [];
  const toReplaceOnPageView = { guesthouse: 'bed and breakfast' };
  const isGtmEnabled = context?.$optimizely?.isFeatureEnabled('web_googleTagManager');

  const event = (options) => {
    const gdpr = new Gdpr();
    const isTrackingAllowed = gdpr.isTrackingAllowed();

    if (!isGtmEnabled || !isTrackingAllowed || typeof options !== 'object') {
      return;
    }

    const { events = [], ...currentOptions } = options;

    instance.push(currentOptions);

    events.forEach((event) => {
      instance.push({ event });
    });
  };

  return {
    onEvent: (eventName, values = {}) => {
      event({
        event: eventName,
        ...values,
      });
    },
    onFormattedEvent: (key, ...values) => {
      event({
        event: key,
        ...Object.assign({}, ...values.map((value, i) => ({ [`parameter${i + 1}`]: value }))),
      });
    },
    onPageView: (values) => {
      // @TODO STATICPWA-670: This will need to be removed but it's to investigate why sometimes gtmVariables aren't being passed
      if (!values.gtmPageName) {
        context.$sentry.captureException(new Error(`GTM Variables problem: ${JSON.stringify(values)}`));
      }

      // TODO: Check for a permanent solution
      if (values && toReplaceOnPageView[values?.gtmPropertyType]) {
        values.gtmPropertyType = toReplaceOnPageView[values.gtmPropertyType];
      }
      event({
        events: ['pageview'],
        ...values,
      });
    },
  };
}
