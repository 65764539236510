const middleware = {}

middleware['currency'] = require('../src/middleware/currency.js')
middleware['currency'] = middleware['currency'].default || middleware['currency']

middleware['env'] = require('../src/middleware/env.js')
middleware['env'] = middleware['env'].default || middleware['env']

middleware['redirect'] = require('../src/middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
